import {
  Box,
  Checkbox,
  Circle,
  Divider,
  Flex,
  HStack,
  HTMLChakraProps,
  Icon,
  Link,
  Skeleton,
  Stack,
  Table,
  TableColumnHeaderProps,
  TableContainer,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Wrap
} from '@chakra-ui/react'
import { Icon as TablerIcon, IconArrowRight, IconClock, IconPlus } from '@tabler/icons-react'
import { AnimatePresence, motion } from 'framer-motion'
import { Easing } from 'framer-motion/types/types'
import { format } from 'friendly-numbers'
import ms from 'ms'
import pluralize from 'pluralize'
import React, { useMemo } from 'react'
import { useMedia } from 'react-use'
import { flatGet } from '../../../../lib/flatGet'
import { App } from '../../../../types/App'
import { Crm } from '../../../../types/Crm'
import { FieldDefinition } from '../../../../types/FieldDefinition'
import { DateTime, ProfileRecord } from '../../../../types/Profile'
import { Project } from '../../../../types/Project'
import { FacetParams } from '../../../data/use-facets'
import { PersistedFieldDefinition, useFieldDefinitions } from '../../../data/use-field-definitions'
import { UrlFilterParams } from '../../../data/use-url-filters'
import { BubbleTag } from '../../../ui/BubbleTag'
import { Card } from '../../../ui/Card'
import {
  ColumnInfo,
  ColumnSelectorDropdown,
  defaultProfileColumns,
  useTableDisplayMode
} from '../../../ui/ColumnSelector'
import { CompanyBubble } from '../../../ui/CompanyBubble'
import { Iconify } from '../../../ui/Iconify'
import { SourceIcon } from '../../../ui/icons'
import { useCurrentProject } from '../../../ui/ProjectsContext'
import { SortableHeader } from '../../../ui/Table'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'
import { UrlHoverControls } from '../../../ui/UrlHoverCard'
import { blocked, RedactedAccountCell, useEntitlements } from '../../../ui/useEntitlements'
import { useOverflow } from '../../../ui/useOverflow'
import { HighlightedField } from '../../accounts/components/DetailsV2/SummaryCard'
import { getItemDisplay } from '../../accounts/facets/categories'
import { accountPath } from '../../accounts/lib/account-path'
import { AllEntitlements } from '../../billing/show'
import { uniqueAndCount } from '../../icps/icp/account-list'
import { Breakdown, LetterGrade, Trendline } from '../../icps/icp/breakdown'
import { TrendCell } from '../../icps/icp/trend-cell'
import { AutoICPAccountScore, HighlightedAccount, Trend } from '../../icps/types'
import { profilePath } from '../lib/path'
import { IntentSignalCell } from './profile-feed'
import { isOnline } from './profile-live-list'

export type HighlightedProfile = ProfileRecord &
  Partial<AutoICPAccountScore> & {
    id: string
    company: HighlightedAccount['company']
    focus_time_trend?: Trend
    page_views_trend?: Trend
    latest_intent_signals?: Array<{
      id: string
      name: string
      last_triggered_at: DateTime
    }>
    limited?: boolean
  }

export interface Column {
  id: string
  Title: React.FC<HTMLChakraProps<'th'>>
  Cell: React.FC<{ profile: HighlightedProfile } & HTMLChakraProps<'td'>>
}

export interface ProfileListProps {
  facetParams?: FacetParams | UrlFilterParams
  profiles: HighlightedProfile[]
  range?: 'day' | 'week' | 'month' | 'all' | 'any'
  columns?: string[]
  extraColumns?: Column[]
  apps?: App[]
  crm?: Crm
  compact?: boolean
  sticky?: boolean
  canAddColumns?: boolean
  loadingColumns?: string[]
  onColumnChange?: (columns: string[]) => void
  onColumnRemove?: (column: string) => void
  onSortChange?: (sort_param: string | undefined) => void
  sortingBy?: string
  noGrays?: boolean
  isLive?: boolean
  selected?: any[]
  getCheckboxProps?: (props: Record<string, any>) => any
  onSelectAll?: () => void
}

function getRangeHeader(range) {
  switch (range) {
    case 'day':
      return '24h'
    case 'week':
      return '7d'
    case 'month':
      return '30d'
    default:
      return 'All time'
  }
}

const defaultColumnKeys = (project?: Project) => {
  if (project?.scoring_enabled) {
    return ['Company', 'FitScore', 'SessionTime', 'Activity', 'LastVisit', 'KQL']
  } else {
    return ['Company', 'SessionTime', 'Activity', 'LastVisit', 'KQL']
  }
}

const rowHover = {
  bg: [undefined, 'gray.50']
}

const columnRenderers = {
  Company: {
    Th: (_props: HeaderRendererProps) => {
      return <Th>Company</Th>
    },
    Td: ({ profile, entitlements }: CellRendererProps) => {
      return (
        <Td height="1px" width="1px" minW="200px" maxW="200px" px={[2, 3]}>
          <Box maxW="200px">
            <RedactedAccountCell
              showLock={false}
              element={profile}
              entitlements={entitlements}
              flexProps={{
                gap: 2
              }}
            >
              {profile.company && (
                <CompanyBubble
                  name={profile.company.name}
                  domain={profile.company.domain}
                  href={accountPath({ company: profile.company })}
                  maxW="180px"
                />
              )}
            </RedactedAccountCell>
          </Box>
        </Td>
      )
    }
  },
  FitScore: {
    Td: ({ profile }: CellRendererProps) => {
      return (
        <Td>
          {(profile.auto_icp_account_score?.fit_grade || profile.fit_grade) && (
            <HStack spacing={2} alignItems="center">
              <LetterGrade
                value={(profile.fit_grade || profile.auto_icp_account_score?.fit_grade)!}
                label={profile.fit_grade_letter || profile.auto_icp_account_score?.fit_grade_letter}
                fontSize="md"
                textAlign="center"
              />
              {profile.company?.domain && <Breakdown domain={profile.company.domain} />}
            </HStack>
          )}
        </Td>
      )
    }
  },
  Online: {
    Th: () => {
      return <Th>Most recent page</Th>
    },
    Td: ({ profile }: CellRendererProps) => {
      return (
        <Td minW="360">
          <Stack w="100%" spacing={1}>
            {profile.currently_viewing && (
              <Card p={0} bg={profile.status !== 'offline' ? `white` : 'gray.50'} shadow="none">
                <UrlHoverControls url={profile.currently_viewing.url}>
                  <HStack fontSize="xs" w="100%" justifyContent={'space-between'} p={1.5}>
                    <HStack>
                      {(isOnline(profile, 5) || profile.status !== 'offline') && (
                        <Box
                          rounded="base"
                          bg="red.500"
                          color="white"
                          fontSize="10px"
                          fontWeight="semibold"
                          textTransform="uppercase"
                          px={1}
                        >
                          LIVE
                        </Box>
                      )}
                      <Text
                        fontSize="xs"
                        color="gray.500"
                        flexWrap="nowrap"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        maxW={'350px'}
                      >
                        {profile.currently_viewing.path === '/'
                          ? profile.currently_viewing.url
                          : profile.currently_viewing.path}
                      </Text>
                    </HStack>

                    <HStack
                      spacing={2}
                      divider={<Divider height="14px" bg="gray.600" orientation="vertical" />}
                      css={{ '[data-url-controls]:hover &': { visibility: 'hidden' } }}
                    >
                      {(profile.currently_viewing.focus_time || 0) > 30_000 && (
                        <HStack spacing="1" color="gray.500">
                          <IconClock size="12" />
                          <Text>{ms(profile.currently_viewing.focus_time!, { long: true })}</Text>
                        </HStack>
                      )}

                      <TimeAgo time={profile.last_seen_at} canToggle={false} />
                    </HStack>
                  </HStack>
                </UrlHoverControls>
              </Card>
            )}
          </Stack>
        </Td>
      )
    }
  },
  SessionTime: {
    Th: (props: HeaderRendererProps) => {
      const intentHeader = getRangeHeader(props.intentRange || 'month')

      return (
        <SortableHeader
          sortBy="focus_time"
          columnKey={props.columnKey}
          currentSort={props.currentSort}
          onSortChange={props.onSortChange}
          onRemoveColumn={props.onRemoveColumn}
        >
          Session Time ({intentHeader})
        </SortableHeader>
      )
    },
    Td: ({ profile, intentRange }: CellRendererProps) => {
      return (
        <Td isNumeric>
          <Flex alignItems="center" gap={4}>
            {Boolean(profile.focus_time_trend?.[intentRange || 'month']?.current?.value) && (
              <Box flex="1 1 50%">
                <TrendCell
                  stats={profile.focus_time_trend}
                  format={(val) => (val ? ms(val) : '—')}
                  range={intentRange || 'month'}
                />
              </Box>
            )}
            {profile.page_views_trend && (
              <Box flex="1 1 50%">
                <TrendCell
                  stats={profile.page_views_trend}
                  format={(val) => (val ? `${format(val)} ${pluralize('page', val)}` : '')}
                  range={intentRange || 'month'}
                  textProps={{
                    color: profile.focus_time_trend?.[intentRange || 'month']?.current?.value ? 'gray.500' : undefined
                  }}
                />
              </Box>
            )}
          </Flex>
        </Td>
      )
    }
  },
  Activity: {
    Th: (props: HeaderRendererProps) => {
      const intentHeader = getRangeHeader(props.intentRange || 'month')
      return <Th>Activity ({intentHeader})</Th>
    },
    Td: ({ profile, intentRange }) => {
      return (
        <Td isNumeric>
          {profile.focus_time_trend && (
            <Box flex="none" display="flex" overflow="hidden" w="100%">
              <Trendline
                color="blue"
                range={intentRange}
                trend={profile.focus_time_trend}
                width={160}
                svgWidth={160}
                height={24}
                svgHeight={24}
              />
            </Box>
          )}
        </Td>
      )
    }
  },
  LastVisit: {
    Td: ({ profile }: CellRendererProps) => {
      return (
        <Td>
          <Text fontSize="sm">
            <TimeAgo time={profile.last_seen_at?.toString()} fallback="—" />
          </Text>
        </Td>
      )
    }
  },
  KQL: {
    Td: ({ profile }: CellRendererProps) => {
      const sortedKQLs = profile.latest_intent_signals ?? []

      return (
        <Td minW="100px" maxW="250px">
          <IntentSignalCell feed={sortedKQLs} />
        </Td>
      )
    }
  },
  sources: {
    Td: (props: CellRendererProps) => {
      const sources: string[] = Array.isArray(props.profile.sources)
        ? props.profile.sources
        : [props.profile.sources].filter(Boolean)

      return (
        <Td width="1px">
          <Flex alignItems="center" gap={1}>
            {sources.map((source) => (
              <SourceIcon key={source} source={source} size={18} />
            ))}
          </Flex>
        </Td>
      )
    }
  },
  'company.linkedin_url': {
    Td: (props: CellRendererProps) => {
      const linkedinUrl = props.profile.company?.linkedin_url

      return (
        <Td width="1px">
          {linkedinUrl && (
            <RedactedAccountCell
              showLock={false}
              element={props.profile}
              type="Visitor"
              entitlements={props.entitlements}
            >
              <Link variant="dotted" href={linkedinUrl} isExternal>
                {linkedinUrl.split('/').reverse()[0]}
              </Link>
            </RedactedAccountCell>
          )}
        </Td>
      )
    }
  },
  linkedin_url: {
    Td: (props: CellRendererProps) => {
      const linkedinUrl = props.profile?.linkedin_url

      return (
        <Td width="1px">
          {linkedinUrl && (
            <RedactedAccountCell
              showLock={false}
              element={props.profile}
              type="Visitor"
              entitlements={props.entitlements}
            >
              <Link variant="dotted" href={linkedinUrl} isExternal>
                {linkedinUrl.split('/').reverse()[0]}
              </Link>
            </RedactedAccountCell>
          )}
        </Td>
      )
    }
  },
  twitter_url: {
    Td: (props: CellRendererProps) => {
      const twitterUrl = props.profile?.twitter_url

      return (
        <Td width="1px">
          {twitterUrl && (
            <RedactedAccountCell
              showLock={false}
              element={props.profile}
              type="Visitor"
              entitlements={props.entitlements}
            >
              <Link variant="dotted" href={twitterUrl} isExternal>
                {twitterUrl.split('/').reverse()[0]}
              </Link>
            </RedactedAccountCell>
          )}
        </Td>
      )
    }
  }
}

interface HeaderRendererProps extends TableColumnHeaderProps {
  columnId: string
  columnKey: string
  columnTitle: string
  columnIcon?: TablerIcon | string | typeof Icon
  columnType?: string
  intentRange?: 'day' | 'week' | 'month'
  sortBy?: string
  currentSort?: string
  onSortChange?: (sortBy: string | undefined) => void
  onRemoveColumn?: (column: string) => void
}

function HeaderRenderer(props: HeaderRendererProps) {
  const ThRenderer = columnRenderers[props.columnId]?.Th
  if (ThRenderer) {
    return <ThRenderer {...props} />
  }

  const { columnId, columnKey, columnTitle, columnIcon, columnType, intentRange, ...rest } = props
  const isNumeric = !!columnType && ['float', 'long', 'number', 'double'].includes(columnType)
  const sortable = rest.sortBy || (!!columnType && !['object', 'nested', 'binary'].includes(columnType))
  const sortBy = sortable ? rest.sortBy || columnKey : undefined

  return (
    <SortableHeader columnKey={columnKey} isNumeric={isNumeric} {...rest} sortBy={sortBy}>
      <Flex gap={1} alignItems="center" isTruncated>
        {columnIcon && <Iconify icon={columnIcon} size={15} flex="none" />}
        <TextEllipsis maxW="100%" tooltip>
          {columnTitle}
        </TextEllipsis>
      </Flex>
    </SortableHeader>
  )
}

interface CellRendererProps {
  columnId: string
  columnKey: string
  columnTitle?: string
  columnIcon?: TablerIcon | string | typeof Icon
  columnType?: string
  isLoading?: boolean
  intentRange?: 'day' | 'week' | 'month'
  profile: any
  entitlements?: AllEntitlements
  compact?: boolean
  isComfy?: boolean
  fieldDefinition?: FieldDefinition
}

function CellRenderer(props: CellRendererProps) {
  const { columnKey, columnType, profile, isLoading, isComfy, fieldDefinition } = props
  const isNumeric = !!columnType && ['float', 'long', 'number', 'double'].includes(columnType)
  const isDate = columnType === 'date'

  if (isLoading) {
    return (
      <Td minW="140px" maxW="300px" isNumeric={isNumeric}>
        <Skeleton height="16px" rounded="base" startColor="gray.50" endColor="gray.200" />
      </Td>
    )
  }

  const CustomRenderer = columnRenderers[props.columnId]?.Td
  if (CustomRenderer) {
    return <CustomRenderer {...props} />
  }

  let value = flatGet(profile, columnKey)
  if (Array.isArray(value) && value.length === 1 && isNumeric) {
    value = value[0]
  }

  const isLongText = typeof value === 'string' && value.length > 100
  let minW = isComfy ? '140px' : '140px'
  const maxW = isComfy ? '600px' : '300px'

  if (isLongText && isComfy) {
    minW = '450px'
  }

  const TagCollectionParent = isComfy ? Wrap : Flex

  return (
    <Td
      minW={minW}
      maxW={maxW}
      isNumeric={isNumeric}
      height={isComfy ? 'auto' : undefined}
      maxH={isComfy ? '300px' : undefined}
      overflowY="auto"
      overflowX="hidden"
      py={isComfy ? 3 : undefined}
    >
      <Box
        minW={minW}
        maxW={maxW}
        whiteSpace={isComfy ? 'normal' : 'nowrap'}
        overflow={isComfy ? 'visible' : 'hidden'}
        textOverflow={isComfy ? 'clip' : 'ellipsis'}
        maxH={isComfy ? '300px' : undefined}
        overflowX="hidden"
        overflowY="auto"
      >
        <RedactedAccountCell showLock={false} element={profile} type="Visitor" entitlements={props.entitlements}>
          {fieldDefinition && value && (
            <HighlightedField field={fieldDefinition as PersistedFieldDefinition} record={profile} fieldValue={value} />
          )}

          {(!fieldDefinition || !value) && (
            <>
              {isDate ? (
                <TimeAgo time={value} mode="full" />
              ) : typeof value === 'string' ? (
                value
              ) : Array.isArray(value) ? (
                <TagCollectionParent flex="1 1 auto" gap={1} isTruncated>
                  {uniqueAndCount(
                    value.filter((v) => ['string', 'boolean', 'number'].includes(typeof v) && v !== '')
                  ).map(([item, count], index) => (
                    <BubbleTag
                      key={JSON.stringify({ item, index })}
                      title={typeof item === 'number' ? item.toLocaleString() : item?.toString()}
                      variant="subtleBorder"
                      value={item}
                    >
                      <TagLabel isTruncated minWidth="10px" maxWidth={isComfy ? '100%' : '180px'}>
                        {count > 1 && <>{count}× </>}{' '}
                        {typeof item === 'number' ? item.toLocaleString() : item?.toString()}
                      </TagLabel>
                    </BubbleTag>
                  ))}
                </TagCollectionParent>
              ) : typeof value === 'number' && isNumeric ? (
                value.toLocaleString()
              ) : value === null || value === undefined ? null : (
                JSON.stringify(value)
              )}
            </>
          )}
        </RedactedAccountCell>
      </Box>
    </Td>
  )
}

function rangeDefault(range) {
  switch (range) {
    case 'day':
    case 'week':
    case 'month':
      return range
    case 'all':
    case 'any':
      return 'month'
    default:
      return 'week'
  }
}

export function ProfileList(props: ProfileListProps) {
  const project = useCurrentProject()
  const entitlements = useEntitlements()
  const apps = props.apps

  const intentRange = rangeDefault(props.range)

  const columnsToDisplay = useMemo(() => {
    const cols = props.columns ?? defaultColumnKeys(project)
    return cols.filter((col) => {
      // dont show fit score column unless enabled for their workspace
      if (col === 'FitScore' && entitlements?.icp_scoring === false) {
        return false
      }

      if (col === 'auto_icp_account_score.fit_grade_letter' && entitlements?.icp_scoring === false) {
        return false
      }

      return true
    })
  }, [project, props.columns, entitlements])

  const displayedColumns: ColumnInfo[] = useMemo(() => {
    return columnsToDisplay.map((column) => {
      return (
        defaultProfileColumns.find((c) => c.id === column || c.key === column) ||
        getItemDisplay(column, apps || [], 'profile')
      )
    })
  }, [columnsToDisplay, apps])

  const largeEnoughScreen = useMedia('(min-width: 768px) and (min-height: 600px)')
  const stickyColumn = !props.compact && props.sticky !== false && largeEnoughScreen
  const facetMappings = props.facetParams?.facetMappings ?? {}

  const { scrollRef, overflowLeft } = useOverflow()
  const [mode] = useTableDisplayMode()
  const isComfy = useMemo(() => mode === 'comfy', [mode])

  // TODO: Use profile field definitions, or accept any time of defn
  const fieldDefsResponse = useFieldDefinitions('account', {
    enabled: true
  })

  return (
    <TableContainer
      ref={scrollRef}
      position="relative"
      className={overflowLeft ? 'scrolled' : undefined}
      fontSize="sm"
      w="100%"
      sx={{ overscrollBehaviorX: 'contain' }}
    >
      <Table variant="bordered" size={isComfy ? 'md' : 'sm'} w="100%" h="1px">
        {!props.compact && (
          <Thead>
            <Tr>
              <Th className={stickyColumn ? 'sticky-column' : undefined} height="38px" paddingLeft={[2.5, 4]}>
                <Flex alignItems="center" gap={2}>
                  {props.onSelectAll && (
                    <Checkbox
                      size="md"
                      marginRight={1}
                      isChecked={props.selected?.length === props.profiles.length}
                      isIndeterminate={
                        (props.selected || []).length > 0 && props.selected?.length !== props.profiles.length
                      }
                      onChange={props.onSelectAll}
                    />
                  )}
                  <Text>Name</Text>
                </Flex>
              </Th>

              {displayedColumns.map((column) => (
                <HeaderRenderer
                  key={`${column.label}:${column.id || column.key}:header`}
                  columnId={column.id || column.key}
                  columnKey={column.key}
                  columnTitle={column.label}
                  columnIcon={column.icon}
                  columnType={column.type || facetMappings[column.key]?.type}
                  intentRange={intentRange}
                  sortBy={column.sortBy}
                  currentSort={props.sortingBy}
                  onSortChange={props.onSortChange}
                  onRemoveColumn={props.onColumnRemove}
                  px={[2, 3]}
                />
              ))}

              {props.extraColumns?.map((c) => <c.Title key={c.id} />)}

              {props.canAddColumns && (
                <Th color="gray.500" _hover={{ bg: 'gray.50', color: 'gray.600' }}>
                  <ColumnSelectorDropdown
                    audienceKind="profile"
                    apps={apps}
                    selectedColumns={props.columns}
                    onChange={props.onColumnChange}
                  >
                    <Flex
                      as="button"
                      type="button"
                      alignItems="center"
                      width="100%"
                      height="100%"
                      gap={1}
                      fontSize="13px"
                      fontWeight="medium"
                    >
                      <Icon as={IconPlus} boxSize={4} />
                      Add column
                    </Flex>
                  </ColumnSelectorDropdown>
                </Th>
              )}
            </Tr>
          </Thead>
        )}
        <AnimatePresence exitBeforeEnter presenceAffectsLayout>
          <Tbody bg="white" position="relative">
            {props.profiles.map((profile, i) => {
              const displayName = profile.name?.trim() || profile.display_name?.trim()
              const delay = Math.min(Math.random() * i * 0.25, 1)

              return (
                <Tr
                  as={motion.tr}
                  key={profile.id}
                  initial={
                    props.isLive
                      ? {
                          height: 0,
                          opacity: 0
                        }
                      : undefined
                  }
                  animate={
                    props.isLive
                      ? {
                          height: '1px',
                          opacity: 1,
                          transition: {
                            duration: 0.2,
                            ease: 'easeIn',
                            delay
                          }
                        }
                      : undefined
                  }
                  exit={
                    props.isLive
                      ? {
                          height: 0,
                          opacity: 0,
                          transition: {
                            duration: 0.2,
                            ease: 'easeOut',
                            delay
                          }
                        }
                      : undefined
                  }
                  width="100%"
                  height="42px"
                  {...blocked(props.noGrays ? undefined : entitlements, profile)}
                  role="group"
                  className={props.selected?.includes(profile.id) ? 'selected-row' : undefined}
                  bg={props.selected?.includes(profile.id) ? 'purple.50' : undefined}
                  _hover={props.selected?.includes(profile.id) ? undefined : rowHover}
                >
                  <Td
                    width="1px"
                    minW="300px"
                    maxW="300px"
                    height="47px"
                    paddingLeft={[2.5, 4]}
                    className={stickyColumn ? 'sticky-column' : undefined}
                  >
                    <Flex alignItems="center" gap={2.5} height="100%">
                      {props.getCheckboxProps && (
                        <Checkbox size="md" marginRight={1} {...props.getCheckboxProps({ value: profile.id })} />
                      )}
                      <RedactedAccountCell
                        element={profile}
                        entitlements={props.noGrays ? undefined : entitlements}
                        type="Visitor"
                        flexProps={{
                          gap: 2
                        }}
                      >
                        <Box width="250px">
                          <Flex
                            as="a"
                            href={profilePath(profile)}
                            display="inline-flex"
                            alignItems="center"
                            gap={1}
                            width="auto"
                            maxW="100%"
                            overflow="hidden"
                            rounded="md"
                            paddingLeft={1}
                            paddingRight={1.5}
                            paddingY={1}
                            marginX={-1}
                            title={displayName || 'Anonymous'}
                            _groupHover={{
                              background: props.selected?.includes(profile.id) ? 'rgba(255,255,255,0.65)' : 'white',
                              shadow: 'sm',
                              '& .hover-icon': {
                                display: 'flex',
                                opacity: 1
                              }
                            }}
                          >
                            <Text
                              flex="1 1 auto"
                              maxWidth="100%"
                              fontSize="sm"
                              fontWeight={displayName ? 'medium' : undefined}
                              isTruncated
                              color={displayName ? undefined : 'gray.500'}
                            >
                              {displayName || 'Anonymous'}
                            </Text>

                            <Circle
                              flex="none"
                              className="hover-icon"
                              display="none"
                              opacity={0}
                              size="18px"
                              bg="gray.100"
                            >
                              <Icon as={IconArrowRight} boxSize={3.5} color="gray.600" />
                            </Circle>
                          </Flex>
                        </Box>
                      </RedactedAccountCell>
                    </Flex>
                  </Td>

                  {displayedColumns.map((column) => {
                    return (
                      <CellRenderer
                        key={`${column.label}:${column.id || column.key}:row:${profile.id}`}
                        columnId={column.id || column.key}
                        columnKey={column.key}
                        columnTitle={column.label}
                        columnIcon={column.icon}
                        columnType={column.type || facetMappings[column.key]?.type}
                        isLoading={props.loadingColumns?.includes(column.key)}
                        profile={profile}
                        intentRange={intentRange}
                        entitlements={props.noGrays ? undefined : entitlements}
                        compact={props.compact}
                        isComfy={isComfy}
                        fieldDefinition={fieldDefsResponse.data?.fields?.find((f) => f.data_source === column.key)}
                      />
                    )
                  })}

                  {props.extraColumns?.map((c) => {
                    return <c.Cell key={c.id + profile.id} profile={profile} />
                  })}

                  {props.canAddColumns && (
                    <Td>
                      <Box minW="200px" />
                    </Td>
                  )}
                </Tr>
              )
            })}
          </Tbody>
        </AnimatePresence>
      </Table>
    </TableContainer>
  )
}

export type StaggerOrigin = 'first' | 'last' | 'center' | number

export type StaggerOptions = {
  startDelay?: number
  from?: StaggerOrigin
  ease?: Easing
}

export function getOriginIndex(from: StaggerOrigin, total: number) {
  if (from === 'first') {
    return 0
  } else {
    const lastIndex = total - 1
    return from === 'last' ? lastIndex : lastIndex / 2
  }
}
