import {
  Box,
  BoxProps,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  MenuGroup,
  MenuItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  Icon as TablerIcon,
  IconArrowLeft,
  IconBolt,
  IconBoxMargin,
  IconBriefcase,
  IconBuilding,
  IconCalendar,
  IconCheck,
  IconClockPlay,
  IconContainer,
  IconFlame,
  IconGripVertical,
  IconLayoutColumns,
  IconMinus,
  IconPackageExport,
  IconPlus,
  IconStar,
  IconTrendingUp,
  IconUsers,
  IconWorldWww,
  IconX
} from '@tabler/icons-react'
import { defaultRangeExtractor, useVirtualizer } from '@tanstack/react-virtual'
import Fuse from 'fuse.js'
import has from 'lodash/has'
import keyBy from 'lodash/keyBy'
import React, { PointerEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { createGlobalState } from 'react-use'
import router from '../../lib/router'
import { App } from '../../types/App'
import { useFieldMappings } from '../data/use-field-mappings'
import { MappingDataType } from '../pages/accounts'
import { Category, getItemDisplay, grouped } from '../pages/accounts/facets/categories'
import { SearchBar } from '../pages/accounts/facets/search-bar'
import { mergeParams } from '../pages/icps/types'
import { Iconify } from './Iconify'
import useLatestRef from './useLatestRef'
import usePrevious from './usePrevious'
import useUpdateEffect from './useUpdateEffect'

export function sameColumns(a?: string[], b?: string[]) {
  const aCsv = Array.from(a ?? [])
    .sort()
    .join(',')
  const bCsv = Array.from(b ?? [])
    .sort()
    .join(',')

  return aCsv == bCsv
}

function checkPath(item: any, parts: string[]): boolean {
  if (!parts.length) {
    return true
  }

  const [current, ...rest] = parts

  if (Array.isArray(item)) {
    return item.some((subItem) => checkPath(subItem, parts))
  }

  if (has(item, current)) {
    return checkPath(item[current], rest)
  }

  return false
}

export function hasColumnData(data: any[], path: string) {
  if (!data || data.length === 0) {
    return true
  }

  return data.some((item) => checkPath(item, path.split('.')))
}

export function hasColumns(data: any[], columns: string[]) {
  if (!data || data.length === 0) {
    return true
  }

  return columns.every((path) => {
    return data.some((item) => checkPath(item, path.split('.')))
  })
}

interface UseUpdateRouteProps {
  data: any[]
  columns: string[]
  initialColumns?: string[]
}

export function useColumns({ data, columns: controlledColumns, initialColumns }: UseUpdateRouteProps) {
  const [columns, setColumnsRaw] = useState<string[]>(controlledColumns)
  const [initial, _setInitial] = useState<string[]>(initialColumns ?? [])
  const [loadingColumns, setLoadingColumns] = useState<string[]>([])
  const prevColumns = usePrevious(columns)

  const setColumns: React.Dispatch<React.SetStateAction<string[]>> = useCallback((newColumns) => {
    if (typeof newColumns === 'function') {
      setColumnsRaw(newColumns)
    } else {
      setColumnsRaw((prev) => {
        if (Array.isArray(prev) && Array.isArray(newColumns) && prev.toString() == newColumns.toString()) {
          return prev
        } else {
          return newColumns
        }
      })
    }
  }, [])

  useUpdateEffect(() => {
    setColumns(controlledColumns)
  }, [controlledColumns.toString()])

  const onColumnChange = useCallback(
    (columns: any[]) => {
      setColumns(columns.map((c) => (typeof c === 'string' ? c : c?.key)).filter(Boolean))
    },
    [setColumns]
  )

  const onColumnRemove = useCallback(
    (column: string | any) => {
      const key = typeof column === 'string' ? column : (column?.key as string | undefined)
      if (key) {
        setColumns((columns) => (columns || []).filter((c) => c !== key))
      }
    },
    [setColumns]
  )

  useUpdateEffect(() => {
    const url = mergeParams(window.location.toString(), { columns: columns.join(',') })

    // no change, no need to update the url or fetch
    if (sameColumns(initial, columns) && !window.location.search.includes('columns=')) {
      return
    }

    // if the column order changes but not the columns just update the url without fetching
    if (sameColumns(prevColumns, columns)) {
      window.history.replaceState({}, '', url)
      return
    }

    // if a column was removed... no need to refetch either
    if (prevColumns && prevColumns.length > columns.length) {
      // window.history.replaceState({}, '', url)
      router.visit(url, { fetch: false })
      return
    }

    // if we already have the data, no need to fetch
    if (hasColumns(data, columns)) {
      // window.history.replaceState({}, '', url)
      router.visit(url, { fetch: false })
      return
    }

    // otherwise, fetch the new columns
    const missingColumns = columns.filter((c) => {
      return !prevColumns?.includes(c) && !hasColumnData(data, c)
    })

    setLoadingColumns(missingColumns)
    router.visit(url)
  }, [prevColumns, columns, initial])

  useUpdateEffect(() => {
    setLoadingColumns([])
  }, [data])

  return {
    columns,
    loadingColumns,
    setColumns,
    onColumnChange,
    onColumnRemove
  }
}

// Block DnD event propagation if element have "data-no-dnd" attribute
const handler = ({ nativeEvent: event }: PointerEvent) => {
  let cur = event.target as HTMLElement

  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false
    }
    cur = cur.parentElement as HTMLElement
  }

  return true
}

export class SmartPointerSensor extends PointerSensor {
  static activators = [{ eventName: 'onPointerDown', handler }] as (typeof PointerSensor)['activators']
}

export type ColumnInfo = {
  id?: string
  key: string
  label: string
  category?: string
  icon?: TablerIcon | string | typeof Icon
  type?: MappingDataType | 'duration' | 'range_stats'
  kind?: 'account' | 'profile'
  sortBy?: string
}

export const additionalColumns: ColumnInfo[] = [
  {
    id: 'Page Views',
    label: 'Page Views',
    key: 'page_views_trend',
    icon: IconWorldWww
  },
  {
    id: 'Session Time',
    label: 'Session Time',
    key: 'focus_time_trend',
    icon: IconClockPlay
  }
]

export const defaultAccountColumns: ColumnInfo[] = [
  {
    id: 'FitScore',
    label: 'Fit',
    key: 'auto_icp_account_score.fit_grade_letter',
    sortBy: 'fit_grade',
    icon: IconStar
  },
  {
    id: 'IntentScore',
    label: 'Intent Score',
    key: 'auto_icp_account_score.intent_score_level',
    sortBy: 'intent_score',
    icon: IconFlame
  },
  {
    id: 'Trend',
    label: 'Trend',
    key: 'intent.trend_14d',
    sortBy: 'intent_trend',
    icon: IconTrendingUp
  },
  {
    id: 'Visitors',
    label: 'Visitors',
    key: 'visitor_stats',
    sortBy: 'visitors',
    icon: IconUsers
  },
  {
    id: 'LastVisit',
    label: 'Last Visit',
    key: 'last_seen_at',
    sortBy: 'last_seen',
    icon: IconCalendar
  },
  {
    id: 'KQL',
    label: 'Signals',
    key: 'signal',
    sortBy: 'latest_intent',
    icon: IconBolt
  },
  {
    id: 'Employees',
    label: 'Employees',
    key: 'company.metrics.employeesRange',
    icon: IconBriefcase
  }
]

export const enabledProfileColumns = (_apps: App[], sources: string[]) => {
  const hasWebActivity = sources.includes('web')
  const hasMultipleSources = sources.length > 1

  let filteredColumns = defaultProfileColumns.filter((c) => {
    if (!hasWebActivity && ['page_views_trend', 'focus_time_trend', 'signal'].includes(c.key)) {
      return false
    }

    return true
  })

  if (hasMultipleSources || !hasWebActivity) {
    filteredColumns = filteredColumns.concat({
      id: 'Source',
      key: 'sources',
      label: 'Source',
      icon: IconPackageExport
    })
  }

  return filteredColumns
}

export const defaultProfileColumns: ColumnInfo[] = [
  {
    id: 'Title',
    label: 'Title',
    key: 'title',
    icon: IconBriefcase
  },
  {
    id: 'Company',
    label: 'Company',
    key: 'company',
    icon: IconBuilding
  },
  {
    id: 'FitScore',
    label: 'Fit',
    key: 'auto_icp_account_score.fit_grade_letter',
    // TODO evaluate fit_grade instead
    sortBy: 'fit_grade',
    icon: IconStar
  },
  {
    id: 'LastVisit',
    label: 'Last Action',
    key: 'last_seen_at',
    sortBy: 'last_seen',
    icon: IconCalendar
  },
  {
    id: 'SessionTime',
    label: 'Session Time',
    key: 'page_views_trend',
    sortBy: 'focus_time',
    icon: IconClockPlay
  },
  {
    id: 'Activity',
    label: 'Activity',
    key: 'focus_time_trend',
    icon: IconClockPlay
  },
  {
    id: 'KQL',
    label: 'Signals',
    key: 'signal',
    sortBy: 'latest_intent',
    icon: IconBolt
  }
]

// internally, we need the id to be the same as the key. and ensure there's a value for both
const defaultColumns = {
  account: defaultAccountColumns.concat(additionalColumns).map((c) => ({ ...c, id: c.key })),
  profile: defaultProfileColumns.map((c) => ({ ...c, id: c.key }))
}

const unsupportedFields = ['uniq_page_views', 'uniq_events']

interface ColumnSelectorProps {
  audienceKind: 'account' | 'profile'
  apps?: App[]
  selectedColumns?: any[]
  disabledColumns?: string[]
  onSelectColumn?: (column: any) => void
  onRemoveColumn?: (column: any) => void
}

export function ColumnSelector({
  audienceKind,
  apps = [],
  selectedColumns,
  disabledColumns = [],
  onSelectColumn,
  onRemoveColumn
}: ColumnSelectorProps) {
  const facetPath = audienceKind === 'account' ? '/accounts/facet-cloud' : '/profiles/facet-cloud'
  const mappings = useFieldMappings(facetPath)
  const noMoreColumns = (selectedColumns || []).length > 20
  const selectedKeys = useMemo(
    () => Object.fromEntries((selectedColumns || []).map((c) => [c.key, true])),
    [selectedColumns]
  )

  const defaults = useMemo(() => {
    const items = defaultColumns[audienceKind]
    return { category: 'Defaults', items } as Category
  }, [audienceKind])

  const categories = useMemo(() => {
    if (!mappings.data?.length) {
      return []
    }

    const all = grouped(keyBy(mappings.data, 'facet'), apps, undefined, unsupportedFields).map((c) => {
      return {
        ...c,
        items: c.items.filter((i) => {
          // filter out Account or Profile specific filters that don't apply to the type of filter
          if (i.kind && i.kind !== audienceKind) {
            return false
          }

          if (disabledColumns.includes(i.key) && !selectedKeys[i.key]) {
            return false
          }

          return true
        })
      }
    })

    return [defaults].concat(all)
  }, [defaults, mappings, apps, audienceKind, disabledColumns, selectedKeys])

  const allItems = useMemo(() => {
    return categories.flatMap((c) => c.items.map((item) => ({ ...item, category: c.category })))
  }, [categories])

  const fuse = useMemo(() => {
    return new Fuse(allItems, {
      keys: ['key', 'label', 'category'],
      minMatchCharLength: 2,
      threshold: 0.3,
      ignoreLocation: true
    })
  }, [allItems])

  const [searchQuery, setSearchQuery] = useState('')

  const displayed = useMemo(() => {
    if (searchQuery.trim().length === 0) {
      return categories
    }

    const results = fuse.search(searchQuery).map((r) => r.item.key)
    return categories
      .map((c) => {
        return {
          ...c,
          items: c.items.filter((i) => results.includes(i.key))
        }
      })
      .filter((c) => c.items.length > 0)
  }, [searchQuery, fuse, categories])

  const rows = useMemo(() => {
    return displayed.reduce((acc, c) => {
      acc.push(c.category)
      acc.push(...c.items)
      return acc
    }, [] as any)
  }, [displayed])

  const parentRef = React.useRef<HTMLDivElement | null>(null)

  const activeStickyIndexRef = React.useRef(0)

  const stickyIndexes = useMemo(() => {
    const headers = displayed.map((c) => c.category)
    return headers.map((header) => rows.findIndex((row) => row === header))
  }, [displayed, rows])

  const isSticky = (index) => stickyIndexes.includes(index)
  const isActiveSticky = (index) => {
    return activeStickyIndexRef.current === index
  }

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 32,
    getScrollElement: () => parentRef.current,
    paddingEnd: 15,
    overscan: 5,
    rangeExtractor: React.useCallback(
      (range) => {
        activeStickyIndexRef.current = [...stickyIndexes].reverse().find((index) => range.startIndex >= index)

        const next = new Set([activeStickyIndexRef.current, ...defaultRangeExtractor(range)])

        return [...next].sort((a, b) => a - b)
      },
      [stickyIndexes]
    )
  })

  if (mappings.isLoading) {
    return (
      <Center minHeight="150px">
        <Spinner color="gray.400" thickness="1.5px" />
      </Center>
    )
  }

  return (
    <>
      <Box paddingX={3} paddingY={4}>
        <SearchBar
          placeholder="Search for columns"
          size="sm"
          onChange={(value) => setSearchQuery(value)}
          debounce={300}
          inputProps={{ autoFocus: true, autoCapitalize: 'off', autoComplete: 'off' }}
        />
      </Box>

      <Box
        ref={parentRef}
        fontSize="sm"
        height="min(300px, calc(50vh - var(--header-height)))"
        paddingX={2}
        overflow="auto"
      >
        <Box position="relative" width="100%" height={`${rowVirtualizer.getTotalSize()}px`}>
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const item = rows[virtualRow.index]
            const sticky = isActiveSticky(virtualRow.index)

            return isSticky(virtualRow.index) ? (
              <Box
                key={virtualRow.key}
                position={sticky ? 'sticky' : 'absolute'}
                transform={sticky ? undefined : `translateY(${virtualRow.start}px)`}
                top={0}
                left={0}
                width="100%"
                height={`${virtualRow.size}px`}
                zIndex={1}
              >
                <Text
                  fontSize="10px"
                  textTransform="uppercase"
                  letterSpacing="widest"
                  color="gray.400"
                  fontWeight="medium"
                  paddingX={2}
                  lineHeight={`${virtualRow.size}px`}
                  bg="white"
                >
                  {item}
                </Text>
              </Box>
            ) : (
              <Box
                key={virtualRow.key}
                top={0}
                left={0}
                width="100%"
                height={`${virtualRow.size}px`}
                position="absolute"
                transform={`translateY(${virtualRow.start}px)`}
              >
                <ColumnItem
                  column={item}
                  isDisabled={(noMoreColumns || disabledColumns.includes(item.key)) && !selectedKeys[item.key]}
                  isSelected={selectedKeys[item.key]}
                  onClick={() => (selectedKeys[item.key] ? onRemoveColumn?.(item) : onSelectColumn?.(item))}
                  rightContent={
                    (onRemoveColumn || !selectedKeys[item.key]) && (
                      <IconButton
                        icon={selectedKeys[item.key] ? <IconMinus size={14} /> : <IconPlus size={14} />}
                        isDisabled={disabledColumns.includes(item.key)}
                        variant="ghost"
                        size="tiny"
                        color="gray.400"
                        _hover={{ bg: 'gray.200' }}
                        aria-label="Add column"
                      />
                    )
                  }
                />
              </Box>
            )
          })}
        </Box>
      </Box>
    </>
  )
}

export function keysToColumns(
  keys: undefined | string[],
  audienceKind: 'profile' | 'account',
  apps: App[] = []
): ColumnInfo[] {
  if (!keys) {
    return []
  }

  return keys.map((key) => {
    const column = defaultColumns[audienceKind]?.find((c) => c.key === key) || getItemDisplay(key, apps, audienceKind)
    if (!column.id) {
      return { ...column, id: column.key }
    }
    return column
  })
}

export const useTableDisplayMode = createGlobalState<'compact' | 'comfy'>('compact')

export function TableMode() {
  const [mode, setMode] = useTableDisplayMode()

  useEffect(() => {
    const mode = localStorage.getItem('table-display-mode')
    if (mode) {
      setMode(mode as 'compact' | 'comfy')
    }
  }, [setMode])

  return (
    <MenuGroup title="Display settings">
      <MenuItem
        onClick={() => {
          setMode('compact')
          localStorage.setItem('table-display-mode', 'compact')
        }}
        icon={<IconBoxMargin size={16} />}
      >
        <HStack justifyContent="space-between">
          <Text>Compact</Text>
          {mode === 'compact' && <IconCheck size={16} />}
        </HStack>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setMode('comfy')
          localStorage.setItem('table-display-mode', 'comfy')
        }}
        icon={<IconContainer size={16} />}
      >
        <HStack justifyContent="space-between">
          <Text>Comfortable</Text>
          {mode === 'comfy' && <IconCheck size={16} />}
        </HStack>
      </MenuItem>
    </MenuGroup>
  )
}

interface SideBySideColumnSelectorProps extends ColumnSelectorProps {
  selectedColumns: any[]
  setSelectedColumns: React.Dispatch<React.SetStateAction<any[]>>
  maxHeight: string | number
}

export function SideBySideColumnSelector({
  apps,
  selectedColumns,
  disabledColumns,
  audienceKind,
  onSelectColumn,
  onRemoveColumn,
  setSelectedColumns,
  maxHeight
}: SideBySideColumnSelectorProps) {
  return (
    <Flex alignItems="stretch">
      <Box
        flex="1 1 50%"
        display="flex"
        flexDir="column"
        py={2}
        minHeight="250px"
        maxHeight={maxHeight}
        borderRight="1px solid"
        borderColor="gray.200"
        overflow="auto"
      >
        <Box paddingX={4} paddingTop={2}>
          <Heading size="xs">Select columns to include</Heading>
        </Box>
        <ColumnSelector
          apps={apps}
          selectedColumns={selectedColumns}
          disabledColumns={disabledColumns}
          audienceKind={audienceKind}
          onSelectColumn={onSelectColumn}
          onRemoveColumn={onRemoveColumn}
        />
      </Box>
      <Box
        flex="1 1 50%"
        display="flex"
        flexDir="column"
        py={2}
        minHeight="250px"
        maxHeight={maxHeight}
        overflow="auto"
      >
        <ManageColumns
          header={
            <Box paddingX={3} paddingTop={1} paddingBottom={3}>
              <Heading size="xs">{selectedColumns.length ? 'Selected columns:' : 'Select some columns...'}</Heading>
            </Box>
          }
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          onRemoveColumn={onRemoveColumn}
        />
      </Box>
    </Flex>
  )
}

interface ColumnSelectionDropdownProps extends ColumnSelectorProps {
  onChange?: (columns: any[]) => void
}

// Add/remove columns and rearrange their order
export function ColumnManagementPopover({ onChange, ...props }: React.PropsWithChildren<ColumnSelectionDropdownProps>) {
  const onChangeRef = useLatestRef(onChange)
  const [selectedColumns, setSelectedColumns] = useState(
    keysToColumns(props.selectedColumns, props.audienceKind, props.apps)
  )

  useUpdateEffect(() => {
    const incoming = Array.from(props.selectedColumns || [])
      .sort()
      .join(',')
    const current = selectedColumns
      .map((c) => c.key)
      .sort()
      .join(',')

    if (incoming !== current) {
      const columns = keysToColumns(props.selectedColumns, props.audienceKind, props.apps)
      setSelectedColumns(columns)
    }
  }, [props.selectedColumns, props.audienceKind, props.apps])

  useUpdateEffect(() => {
    onChangeRef.current?.(selectedColumns)
  }, [selectedColumns])

  const onSelectColumn = useCallback((column) => {
    setSelectedColumns((columns) => {
      if ((columns || []).length <= 20) {
        return columns.concat([column])
      }

      return columns
    })
  }, [])

  const onRemoveColumn = useCallback((column) => {
    setSelectedColumns((columns) => columns.filter((c) => c.key !== column.key))
  }, [])

  return (
    <Popover placement="bottom-end" isLazy lazyBehavior="keepMounted">
      <PopoverTrigger>
        {props.children || (
          <Button variant="outline" size="sm" flex="none" leftIcon={<IconLayoutColumns size={16} />} iconSpacing={1.5}>
            Columns
          </Button>
        )}
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          width="min(550px, 95vw)"
          maxH="min(450px, calc(85vh - var(--header-height)))"
          overflow="hidden"
          rounded="lg"
          shadow="xl"
          _focus={{
            outline: 'none',
            boxShadow: 'xl'
          }}
        >
          <SideBySideColumnSelector
            {...props}
            selectedColumns={selectedColumns}
            onSelectColumn={onSelectColumn}
            onRemoveColumn={onRemoveColumn}
            setSelectedColumns={setSelectedColumns}
            maxHeight="450px"
          />
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export function ColumnSelectionDropdown({ onChange, ...props }: React.PropsWithChildren<ColumnSelectionDropdownProps>) {
  const [mode, setMode] = useState<'sorting' | 'adding'>('sorting')
  const onChangeRef = useLatestRef(onChange)

  const [selectedColumns, setSelectedColumns] = useState(
    keysToColumns(props.selectedColumns, props.audienceKind, props.apps)
  )

  useUpdateEffect(() => {
    const incoming = Array.from(props.selectedColumns || [])
      .sort()
      .join(',')
    const current = selectedColumns
      .map((c) => c.key)
      .sort()
      .join(',')

    if (incoming !== current) {
      const columns = keysToColumns(props.selectedColumns, props.audienceKind, props.apps)
      setSelectedColumns(columns)
    }
  }, [props.selectedColumns, props.audienceKind, props.apps])

  useUpdateEffect(() => {
    onChangeRef.current?.(selectedColumns)
  }, [selectedColumns])

  const onSelectColumn = useCallback((column) => {
    setSelectedColumns((columns) => {
      if ((columns || []).length <= 20) {
        return columns.concat([column])
      }

      return columns
    })
  }, [])

  const onRemoveColumn = useCallback((column) => {
    setSelectedColumns((columns) => columns.filter((c) => c.key !== column.key))
  }, [])

  return (
    <Popover placement="bottom-end" isLazy lazyBehavior="keepMounted" onOpen={() => setMode('sorting')}>
      <PopoverTrigger>
        {props.children || (
          <Button variant="outline" size="sm" flex="none" leftIcon={<IconLayoutColumns size={16} />} iconSpacing={1.5}>
            Columns
          </Button>
        )}
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          width="min(300px, 95vw)"
          maxH="min(450px, calc(85vh - var(--header-height)))"
          overflow="hidden"
          rounded="lg"
          shadow="xl"
          _focus={{
            outline: 'none',
            boxShadow: 'xl'
          }}
        >
          {mode === 'sorting' ? (
            <ManageColumns
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              onRemoveColumn={onRemoveColumn}
              requestAddColumns={() => setMode('adding')}
            />
          ) : (
            <Box flex="1 1 auto" display="flex" flexDirection="column" height="100%" minHeight="200px">
              <Box paddingX={4} paddingY={2}>
                <Button
                  size="sm"
                  fontSize="xs"
                  variant="link"
                  _hover={{ textDecoration: 'none', color: 'purple.600' }}
                  bg="white"
                  color="gray.600"
                  onClick={() => setMode('sorting')}
                  leftIcon={<IconArrowLeft size={14} />}
                  iconSpacing={1}
                >
                  Back
                </Button>
              </Box>

              <Divider />

              <ColumnSelector
                {...props}
                selectedColumns={selectedColumns}
                onRemoveColumn={onRemoveColumn}
                onSelectColumn={onSelectColumn}
              />
            </Box>
          )}
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

const focusProps = {
  outline: 'none',
  boxShadow: 'xl'
}

export function ColumnSelectorDropdown({ onChange, ...props }: React.PropsWithChildren<ColumnSelectionDropdownProps>) {
  const onChangeRef = useLatestRef(onChange)
  const disclosure = useDisclosure()

  const [selectedColumns, setSelectedColumns] = useState(
    keysToColumns(props.selectedColumns, props.audienceKind, props.apps)
  )

  useUpdateEffect(() => {
    const incoming = Array.from(props.selectedColumns || [])
      .sort()
      .join(',')
    const current = selectedColumns
      .map((c) => c.key)
      .sort()
      .join(',')

    if (incoming !== current) {
      const columns = keysToColumns(props.selectedColumns, props.audienceKind, props.apps)
      setSelectedColumns(columns)
    }
  }, [props.selectedColumns, props.audienceKind, props.apps])

  useUpdateEffect(() => {
    onChangeRef.current?.(selectedColumns)
  }, [selectedColumns])

  const onClose = disclosure.onClose

  const onSelectColumn = useCallback(
    (column) => {
      onClose()
      setSelectedColumns((columns) => {
        if ((columns || []).length <= 20) {
          return columns.concat([column])
        }

        return columns
      })
    },
    [onClose]
  )

  return (
    <Popover placement="bottom-start" isLazy lazyBehavior="keepMounted" {...disclosure}>
      <PopoverTrigger>{props.children}</PopoverTrigger>
      <Portal>
        <PopoverContent width="min(300px, 95vw)" overflow="hidden" rounded="lg" shadow="xl" _focus={focusProps}>
          <Box flex="1 1 auto" display="flex" flexDirection="column" height="100%">
            <ColumnSelector {...props} selectedColumns={selectedColumns} onSelectColumn={onSelectColumn} />
          </Box>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

interface ManageColumnsProps {
  header?: React.ReactNode
  selectedColumns: any[]
  setSelectedColumns: React.Dispatch<React.SetStateAction<any[]>>
  onRemoveColumn?: (column: any) => void
  requestAddColumns?: () => void
}

export function ManageColumns({ setSelectedColumns, ...props }: ManageColumnsProps) {
  const sensors = useSensors(
    useSensor(SmartPointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event

      if (active.id !== over.id) {
        setSelectedColumns((items) => {
          const oldIndex = items.findIndex((item) => item.id === active.id)
          const newIndex = items.findIndex((item) => item.id === over.id)
          return arrayMove(items, oldIndex, newIndex)
        })
      }
    },
    [setSelectedColumns]
  )

  return (
    <Box flex="1 1 auto" maxH="100%" minH="40px" display="flex" flexDirection="column" padding={1}>
      <Stack spacing={1} maxH="inherit" minH="inherit">
        {props.header || (
          <Text fontSize="xs" fontWeight="medium" color="gray.500" paddingX={2} paddingY={1}>
            Columns
          </Text>
        )}

        <Box position="relative" flex="1 1 auto" minH={0} display="flex" flexDirection="column" overflow="auto">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={props.selectedColumns} strategy={verticalListSortingStrategy}>
              {props.selectedColumns.map((column) => (
                <DraggableColumn key={column.id || column.key} column={column} onRemoveColumn={props.onRemoveColumn} />
              ))}
            </SortableContext>
          </DndContext>
        </Box>

        {props.requestAddColumns && (
          <>
            <Divider />

            <Flex
              as="button"
              alignItems="center"
              gap={1.5}
              fontSize="sm"
              fontWeight="medium"
              rounded="md"
              outline="none"
              width="100%"
              userSelect="none"
              color="gray.700"
              paddingX={2}
              paddingY={1.5}
              _focus={{ bg: 'gray.100' }}
              _hover={{ bg: 'gray.100' }}
              onClick={props.requestAddColumns}
            >
              <Icon as={IconPlus} boxSize={3.5} />
              Add column
            </Flex>
          </>
        )}
      </Stack>
    </Box>
  )
}

interface DraggableColumnProps {
  column: any
  onRemoveColumn?: (column: any) => void
}

function DraggableColumn({ column, onRemoveColumn }: DraggableColumnProps) {
  const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({
    id: column.key
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    outline: 'none',
    touchAction: 'none'
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <ColumnItem
        column={column}
        showCategory
        isHighlighted={isDragging}
        cursor={isDragging ? 'grabbing' : 'grab'}
        leftContent={<Icon as={IconGripVertical} boxSize={3.5} color="gray.400" />}
        rightContent={
          typeof onRemoveColumn === 'function' ? (
            <IconButton
              icon={<IconX size={14} />}
              variant="ghost"
              size="tiny"
              color="gray.400"
              _hover={{ bg: 'gray.200' }}
              aria-label="Remove column"
              data-no-dnd="true"
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => {
                e.stopPropagation()
                onRemoveColumn(column)
              }}
            />
          ) : undefined
        }
      />
    </div>
  )
}

interface ColumnItemProps extends BoxProps {
  column: any
  isHighlighted?: boolean
  isDisabled?: boolean
  isSelected?: boolean
  showCategory?: boolean
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
}

function ColumnItem({
  column,
  isHighlighted,
  isDisabled,
  isSelected,
  showCategory,
  leftContent,
  rightContent,
  onClick,
  ...props
}: ColumnItemProps) {
  return (
    <Box
      display="flex"
      gap={1.5}
      alignItems="center"
      role="button"
      tabIndex={0}
      outline="none"
      userSelect="none"
      isTruncated
      width="100%"
      flex="1 0 0%"
      paddingX={2}
      paddingY={1.5}
      fontSize="sm"
      fontWeight="medium"
      rounded="md"
      lineHeight="20px"
      bg={isHighlighted ? 'background.light' : 'transparent'}
      opacity={isHighlighted ? 0.9 : 1}
      color={isDisabled || isSelected ? 'gray.400' : 'gray.700'}
      _hover={{ bg: 'background.light' }}
      onClick={isDisabled ? undefined : onClick}
      {...props}
    >
      {leftContent}
      <Flex flex="none">
        <Iconify icon={column.icon} size={15} strokeWidth={2} />
      </Flex>
      <Text flex="1 1 auto" isTruncated>
        {showCategory ? [column.category, column.label].filter(Boolean).join(' ') : column.label}
      </Text>
      {rightContent}
    </Box>
  )
}
