import {
  Badge,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Image,
  Img,
  Input,
  Link,
  Stack
} from '@chakra-ui/react'
import { IconCopy } from '@tabler/icons-react'
import React, { useCallback } from 'react'
import { toast } from 'sonner'
import { LightBgCard } from '../../../ui/Card'
import PageDescription from '../../../ui/PageDescription'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import { useCopyToClipboard } from '../../../ui/useCopyToClipboard'
import rb2bApps from './assets/rb2b_apps.jpg'
import rb2bWebhook from './assets/rb2b_webhook.jpg'

interface Props {
  title: string
  description: string
  logo: string
  settings: Record<string, string | null | undefined>
  deps: {
    api_key: string
  }
}

export default function Show(props: Props) {
  const url = `https://api.getkoala.com/apps/rb2b/ingest/${props.deps.api_key}`
  const { copy } = useCopyToClipboard()

  const copyToClipboard = useCallback(
    (url: string) => {
      copy(url)
      toast(`Copied webhook URL to clipboard`)
    },
    [copy]
  )

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/rb2b'),
            title: 'RB2B'
          }
        ]}
        offscreen
      />

      <HStack>
        <Image src={props.logo} maxW="6" />
        <PageTitle>{props.title}</PageTitle>
        <Badge colorScheme={'purple'}>Beta</Badge>
      </HStack>
      <PageDescription>
        {props.description} Koala will receive events from RB2B via this webhook. Identities will be merged via the
        Koala Identity Graph.
      </PageDescription>

      <Stack spacing="8" pt="8">
        <Heading size="sm" fontWeight={'semibold'}>
          Webhook Settings
        </Heading>

        <LightBgCard as={Stack}>
          <Stack spacing={'4'}>
            <FormControl id="api_key">
              <FormLabel>Webhook URL</FormLabel>
              <HStack>
                <Input readOnly bg="gray.50" w="100%" defaultValue={url} />
                <IconButton
                  onClick={() => copyToClipboard(url)}
                  aria-label="Copy"
                  icon={<IconCopy size="14" />}
                  size="sm"
                  variant={'ghost'}
                />
              </HStack>
              <FormHelperText>
                Copy the URL above, and paste it in the{' '}
                <Link color="purple.500" href="https://app.rb2b.com/integrations/webhook" isExternal>
                  Webhooks integration
                </Link>{' '}
                section in RB2B.
              </FormHelperText>
            </FormControl>
          </Stack>
        </LightBgCard>

        <Heading size="sm" fontWeight={'semibold'}>
          How to connect RB2B to Koala
        </Heading>
        <Heading size="xs" fontWeight={'semibold'}>
          Step 1: Open the{' '}
          <Link color="purple.500" href="https://app.rb2b.com/integrations" isExternal>
            RB2B Integrations page
          </Link>{' '}
          and select <b>Webhooks</b>.
        </Heading>
        <Img src={rb2bApps} />
        <Heading size="xs" fontWeight={'semibold'}>
          Step 2: Paste the webhook URL into the <b>Webhook URL</b> field and click <b>Verify</b>.
        </Heading>
        <Img src={rb2bWebhook} />
      </Stack>
    </PageLayout>
  )
}
