import {
  Box,
  Button,
  Code,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Stack
} from '@chakra-ui/react'
import React from 'react'
import { Apps } from '../../../../types/App'
import { Territory } from '../../../../types/Territory'
import { useFacets } from '../../../data/use-facets'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { GrayCard } from '../../../ui/Card'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath } from '../../../ui/ProjectsContext'
import { FilterPreview } from '../../accounts/components/FilterPreview'

interface Props {
  apps: Apps
  territory: Territory
  errors?: {
    [key: string]: string[]
  }
}

const emptyObject = {}
const emptyArray = []

export function TerritoryForm(props: Props) {
  const [submitting, setSubmitting] = React.useState(false)
  const [name, setName] = React.useState(props.territory?.name ?? '')
  const nameError = name === (props.territory?.name || '') ? props.errors?.name?.join(', ') : ''

  const { hasPermission: canManageMembers } = usePermission({ on: 'project', action: 'can_manage_members' })

  const facets = useFacets({
    facet_filters: props.territory.filters?.facets || emptyObject
  })

  return (
    <form action={projectPath(`/territories/${props.territory.id}`)} method="POST" onSubmit={() => setSubmitting(true)}>
      <AuthenticityToken />
      {props.territory.id && <input type="hidden" name="_method" value="PATCH" />}
      <GrayCard w="100%">
        <Stack spacing={8} w="100%">
          <FormControl id="territory[name]" isInvalid={!!nameError} isRequired>
            <FormLabel>Territory name</FormLabel>
            <Input
              type="text"
              bg="white"
              name="territory[name]"
              placeholder="e.g. SDR Territory, EMEA Mid-Market, etc"
              size="sm"
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
            <FormErrorMessage>{nameError}</FormErrorMessage>
          </FormControl>

          <FormControl id="territory[filters]" isRequired>
            <FormLabel>Territory filters</FormLabel>
            <FormHelperText>
              Define the fields that make up this territory. You can use ownership fields with the{' '}
              <Code color="pink.600">Current user</Code> value which is scoped to the current viewer, or you can filter
              by geography, industry, number of employees, estimated revenue, and more.
            </FormHelperText>

            <Box pt={4}>
              <FilterPreview
                {...facets}
                kind="account"
                apps={props.apps}
                canClearFilters={false}
                topFilters={emptyArray}
                shouldShowIntentFilters={false}
                shouldShow3rdPartyFilters={false}
                shouldShowActiveVisitorsFilters={false}
                shouldShowLastSeenFilter={false}
                shouldShowUserAttributeFilters={false}
                shouldShowTraits={false}
                range={undefined}
              />
            </Box>

            <input type="hidden" name="territory[filters]" value={facets.queryString.split('?')[1] || ''} />
          </FormControl>

          <Flex
            alignItems="center"
            justifyContent="flex-end"
            gap={3}
            pt={5}
            borderTop="1px solid"
            borderColor="gray.200"
          >
            <Button size="sm" variant="outline" as={Link} href={projectPath('/territories')}>
              Cancel
            </Button>

            <Button
              type="submit"
              size="sm"
              colorScheme="purple"
              isLoading={submitting}
              isDisabled={!canManageMembers || !facets.isFiltering}
            >
              {props.territory.id ? 'Update territory' : 'Create territory'}
            </Button>
          </Flex>
        </Stack>
      </GrayCard>
    </form>
  )
}
