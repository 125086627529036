import React, { useEffect, useState, useRef } from 'react'
import { Box, Progress, ScaleFade, Text, Flex, Icon, Button } from '@chakra-ui/react'
import { IconMinus, IconPencil, IconArrowsDiagonal } from '@tabler/icons-react'
import { projectPath, useCurrentProject } from '../../../ui/ProjectsContext'
import { subscribeToChannel, SubscriptionEmitter } from '../../../../channels/generic_channel'
import { throttle } from 'lodash'

interface ImportListPromptProps {
  show?: boolean
  listIsRenamed?: boolean
  listId?: string
  onRenameListClick?: () => void
}

export function ImportListPrompt(props: React.PropsWithChildren<ImportListPromptProps>) {
  const [promptMinimized, setPromptMinimized] = useState(false)
  const [importInfoLevelData, setImportInfoLevelData] = useState<number | undefined>(0)
  const listId = props.listId
  const listIsRenamed = props.listIsRenamed
  const subscription = useRef<SubscriptionEmitter>()

  const project = useCurrentProject()

  const handleImportInfo = React.useCallback(
    (message: { action: string; level?: number }) => {
      if (!project?.slug || !message) {
        return
      }

      if (message.action === 'finished') {
        setImportInfoLevelData(100)
      } else {
        setImportInfoLevelData(message.level)
      }
    },
    [project?.slug]
  )

  const importInProgress = !importInfoLevelData || importInfoLevelData > 100

  useEffect(() => {
    if (subscription.current || !project?.slug) {
      return
    }

    subscription.current = subscribeToChannel({
      channel: 'StaticListsChannel',
      id: listId,
      project_slug: project.slug
    })

    const sub = subscription.current
    const update = throttle(handleImportInfo, 2000)
    sub.on('received', update)

    const unsub = () => {
      update.cancel()
      sub?.off('received', update)
      sub?.unsubscribe()
    }

    return () => {
      unsub()
      subscription.current = undefined
    }
  }, [listId, project?.slug, handleImportInfo])

  const handleMinMaxIconClick = () => {
    if (!promptMinimized) {
      setPromptMinimized(true)
    } else {
      setPromptMinimized(false)
    }
  }
  return (
    <Box
      display="flex"
      justifyContent="center"
      position="absolute"
      bottom="32px"
      right={4}
      pointerEvents="none"
      width="349px"
      zIndex={999}
      background="white"
    >
      <ScaleFade in={props.show !== false}>
        <Box
          display="flex"
          flexDir="column"
          gap={3}
          alignItems="flex-start"
          zIndex="sticky"
          bg="white"
          fontSize="sm"
          rounded="lg"
          width="349px"
          padding={4}
          pointerEvents="auto"
          boxShadow="rgba(28, 40, 64, 0.06) 0px 0px 0px 1px inset, rgba(28, 40, 64, 0.12) 0px 4px 8px -4px, rgba(28, 40, 64, 0.16) 0px 4px 12px -2px"
          background="linear-gradient(180deg, rgba(77, 50, 228, 0.04) 0%, rgba(77, 50, 228, 0.00) 100%)"
        >
          <Flex direction="row" justify="space-between" alignItems="center" width="100%">
            {!promptMinimized && (
              <Text fontSize="md" fontWeight="600">
                📋 Your list is being created
              </Text>
            )}
            {promptMinimized && (
              <Text fontSize="sm" fontWeight="400">
                Importing {`${importInfoLevelData}%`}
              </Text>
            )}
            <Icon
              as={promptMinimized ? IconArrowsDiagonal : IconMinus}
              color="purple.500"
              onClick={() => handleMinMaxIconClick()}
            />
          </Flex>

          {!promptMinimized && (
            <>
              <Text fontSize="sm">
                {importInProgress
                  ? 'Your list is being created. You can leave the page or play with the Prospector and your list will continue to run in the background.'
                  : 'Your list is ready!'}
              </Text>
              <Flex direction="row" justify="space-between" alignItems="center" width="100%">
                <Text fontSize="sm" fontWeight="600">
                  Importing
                </Text>
                <Text fontSize="sm" fontWeight="600">
                  {`${importInfoLevelData}%`}
                </Text>
              </Flex>
            </>
          )}
          <Progress
            colorScheme={importInProgress ? 'purple' : 'green'}
            size="xs"
            width="100%"
            value={importInfoLevelData}
          />
          {!promptMinimized && (
            <Flex direction="row" alignItems="center" width="100%" onClick={props.onRenameListClick}>
              {!listIsRenamed ? (
                <>
                  <Icon as={IconPencil} color="purple.500" size="sm" marginRight={1} />
                  <Text fontSize="sm" fontWeight="500" color="purple.500">
                    Rename list
                  </Text>
                </>
              ) : (
                <Button as="a" size="md" width="100%" colorScheme="purple" href={projectPath(`/lists/${listId}`)}>
                  View list
                </Button>
              )}
            </Flex>
          )}
        </Box>
      </ScaleFade>
    </Box>
  )
}
