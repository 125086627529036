import { Circle, Flex, FlexProps, Icon, Text } from '@chakra-ui/react'
import { IconArrowRight } from '@tabler/icons-react'
import React from 'react'
import Avatar from './Avatar'

type Props = FlexProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    src?: string
    name?: string
    email?: string
    iconRight?: boolean
  }

const hoverStyle = {
  background: 'white',
  shadow: 'sm',

  '& .hover-icon': {
    display: 'flex',
    opacity: 1
  }
}

export function PersonBubble({ src, name, email, href, iconRight, ...rest }: Props) {
  return (
    <Flex
      as={href ? 'a' : undefined}
      href={href}
      display="inline-flex"
      alignItems="center"
      gap={1.5}
      rounded="lg"
      paddingLeft="3px"
      paddingRight={href ? '5px' : '3px'}
      marginLeft="-3px"
      marginRight={href ? '-5px' : '-3px'}
      paddingY="3px"
      maxW="100%"
      isTruncated
      fontWeight="medium"
      _hover={hoverStyle}
      title={name || email}
      {...rest}
    >
      <Avatar size="tiny" name={name} src={src} />

      <Text fontSize="sm" minWidth="80px" lineHeight="1.2" fontWeight="inherit" isTruncated>
        {name || email}
      </Text>

      {iconRight !== false && href && (
        <Circle flex="none" className="hover-icon" display="none" opacity={0} size="18px" bg="gray.100">
          <Icon as={IconArrowRight} boxSize={3.5} color="gray.600" />
        </Circle>
      )}
    </Flex>
  )
}
