import React from 'react'

import { projectPath } from '../../../ui/ProjectsContext'

import PageLayout from '@app/components/ui/PageLayout'
import PageTitle from '@app/components/ui/PageTitle'
import type { Project } from '@app/types/Project'
import PageDescription from '../../../ui/PageDescription'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'

import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import { Badge, Box, HStack, Image, Stack } from '@chakra-ui/react'
import { Introduction } from '../components/Introduction'
import { PostsList, TrackedPost } from './posts-list'

interface Props {
  app_id: string
  project: Project
  title: string
  description: string
  logo: string
  valid?: boolean
  connected?: boolean
  settings: {}
  deps: {
    tracked_posts: TrackedPost[]
  }
}

export default function Show(props: Props) {
  return (
    <PageLayout size={props.deps.tracked_posts.length > 0 ? 'md' : 'sm'}>
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/linkedin_xt'),
            title: 'Chrome Extension for LinkedIn'
          }
        ]}
        offscreen
      />
      <HStack w="100%">
        <Box w="100%">
          <HStack>
            <HStack marginRight="auto" alignItems="center" spacing={2}>
              <Image src={props.logo} maxW="6" />
              <PageTitle>{props.title}</PageTitle>
              {props.connected && props.valid && <Badge colorScheme="green">Connected</Badge>}
            </HStack>
            {props.connected && <DisconnectAppDialog appTitle="LinkedIn" showRemoveCachesOption={false} />}
          </HStack>

          <PageDescription>{props.description}</PageDescription>
        </Box>
      </HStack>

      <Stack spacing={16}>
        <Introduction
          app="LinkedIn"
          icon={props.logo}
          description="Use our Chrome Extension to track reactions and comments on LinkedIn posts. Reach out to support@getkoala.com to request access."
        />

        <PostsList trackedPosts={props.deps.tracked_posts} />
      </Stack>
    </PageLayout>
  )
}
