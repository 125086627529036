import React from 'react'
import { Account } from '../../../../../types/Account'
import { VisitorsBreakdown } from '../VisitorsBreakdown'

interface Props {
  account: Account
}

export function People(props: Props) {
  return <VisitorsBreakdown {...props.account} />
}
