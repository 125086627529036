import {
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { IconAdjustmentsHorizontal, IconEye, IconEyeOff, IconGripHorizontal } from '@tabler/icons-react'
import Fuse from 'fuse.js'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'sonner'
import router from '../../../lib/router'
import { Apps } from '../../../types/App'
import {
  PersistedFieldDefinition,
  useUpdateFieldDefinition,
  useUpdateFieldDefinitions
} from '../../data/use-field-definitions'
import { SalesforceIcon } from '../../ui/icons'
import { HubSpotIcon } from '../../ui/icons/HubspotIcons'
import MultipleSelectInput from '../../ui/MultipleSelectInput'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import SettingsHeader from '../../ui/SettingsHeader'
import { SearchBar } from '../accounts/facets/search-bar'
import { FieldDefinitionMenu } from './components/FieldDefinitionMenu'
import { dataTypeIcons, FieldDefinitionModal } from './components/FieldDefinitionModal'

interface Props {
  apps?: Apps
  fields: PersistedFieldDefinition[]
  record_type: 'account' | 'profile'
}

export default function Index(props: Props) {
  const index = ['account', 'profile'].indexOf(props.record_type)

  return (
    <PageLayout size="full" gap={0}>
      <SettingsHeader border="none">
        <Flex alignItems="center" justifyContent="space-between">
          <PageTitle>Custom Fields</PageTitle>
        </Flex>
        <PageDescription>
          Define custom fields to apply a specific schema to account or profile traits, or highlight fields from your
          CRM.
        </PageDescription>
      </SettingsHeader>

      <Box>
        <Tabs
          size="sm"
          variant="line"
          defaultIndex={index || 0}
          onChange={(index) => {
            const recordType = index === 0 ? 'accounts' : 'profiles'
            router.visit(projectPath(`/${recordType}/fields`))
          }}
        >
          <TabList>
            <Tab>Account fields</Tab>
            <Tab>Profile fields</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              <Fields fields={props.fields} recordType="account" apps={props.apps} />
            </TabPanel>
            <TabPanel p={0}>
              <Fields fields={props.fields} recordType="profile" apps={props.apps} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </PageLayout>
  )
}

interface FieldsProps {
  fields: PersistedFieldDefinition[]
  apps?: Apps
  recordType: 'account' | 'profile'
}

function Fields(props: FieldsProps) {
  const [fields, setFields] = useState(props.fields)
  const [fieldToEdit, setFieldToEdit] = React.useState<null | PersistedFieldDefinition>(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])

  const fuse = useMemo(() => {
    return new Fuse(fields, {
      keys: ['label', 'data_source'],
      threshold: 0.3
    })
  }, [fields])

  const filteredFields = useMemo(() => {
    const results = searchQuery ? fuse.search(searchQuery).map((result) => result.item) : fields

    if (selectedFilters.length === 0) {
      return results
    }

    return results.filter((field) => {
      if (selectedFilters.includes('Hide defaults') && !field.is_custom) {
        return false
      }

      if (selectedFilters.includes('Salesforce') && isSalesforceField(field)) {
        return true
      }

      if (selectedFilters.includes('HubSpot') && isHubSpotField(field)) {
        return true
      }

      if (selectedFilters.includes('Traits') && isTraitField(field)) {
        return true
      }

      if (selectedFilters.length === 1 && selectedFilters.includes('Hide defaults')) {
        return true
      }

      return false
    })
  }, [fuse, searchQuery, fields, selectedFilters])

  const handleFilterChange = useCallback((changes) => {
    setSelectedFilters(changes.selectedItems)
  }, [])

  const editFieldModal = useDisclosure()
  const { isLoading: isUpdatingAll, mutateAsync: updateAllFields } = useUpdateFieldDefinitions()
  const { isLoading: isUpdatingField, mutateAsync: updateField } = useUpdateFieldDefinition()

  useEffect(() => {
    if (props.fields.length) {
      setFields(props.fields)
    }
  }, [props.fields])

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over, activatorEvent } = event

      // prevent default link behavior
      activatorEvent.preventDefault()
      activatorEvent.stopPropagation()

      if (active.id !== over?.id) {
        const oldIndex = fields.findIndex((item) => item.id === active.id)
        const newIndex = fields.findIndex((item) => item.id === over?.id)
        const newItems = arrayMove(fields, oldIndex, newIndex)

        // update the state immediately
        setFields(newItems)

        // attempt to persist the change
        updateAllFields({ recordType: props.recordType, fields: newItems }).catch((err) => {
          toast.error('There was an issue reordering fields', { description: (err as any)?.message })
        })
      }
    },
    [fields, updateAllFields, props.recordType]
  )

  const onUpdateField = useCallback(
    async (field: PersistedFieldDefinition) => {
      let og: PersistedFieldDefinition

      setFields((prev) =>
        prev.map((prevField) => {
          if (prevField.id === field.id) {
            og = prevField
            return field
          }
          return prevField
        })
      )

      try {
        await updateField({ id: field.id, field })
      } catch (err) {
        // undo the change!
        setFields((prev) =>
          prev.map((prevField) => {
            if (prevField.id === field.id) return og
            return prevField
          })
        )
      }
    },
    [updateField]
  )

  const onFieldDeleted = useCallback((id: string) => {
    setFields((prev) => prev.filter((field) => field.id !== id))
  }, [])

  return (
    <Box width="100%" position="relative" paddingY={4}>
      <Flex justifyContent="space-between" alignItems="center" mb={4} gap={3}>
        <SearchBar
          placeholder="Search…"
          size="sm"
          onChange={(value) => setSearchQuery(value)}
          debounce={300}
          inputProps={{ autoCapitalize: 'off', autoComplete: 'off' }}
        />
        <MultipleSelectInput
          items={['Traits', 'Salesforce', 'HubSpot', 'Hide defaults']}
          size="sm"
          triggerProps={{
            leftIcon: <IconAdjustmentsHorizontal size={16} />,
            flex: 'none',
            iconSpacing: 2,
            justifyContent: 'flex-start',
            textAlign: 'left'
          }}
          popperOptions={{ placement: 'bottom-end' }}
          variant="outline"
          selectedItems={selectedFilters}
          onSelectedItemsChange={handleFilterChange}
          placeholder="Filter"
        />
        <Button
          flex="none"
          size="sm"
          colorScheme="purple"
          onClick={() => {
            setFieldToEdit(null)
            editFieldModal.onOpen()
          }}
        >
          Create Field
        </Button>
      </Flex>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <TableContainer w="100%">
          <Table size="sm" variant="unstyled">
            <Thead borderBottom="1px solid" borderColor="border.lightest">
              <Tr>
                <Th fontSize="xs" height="32px" pl={9}>
                  Field
                </Th>
                <Th fontSize="xs">Data type</Th>
                <Th fontSize="xs">Source</Th>
                <Th fontSize="xs"></Th>
              </Tr>
            </Thead>
            <SortableContext items={fields} strategy={verticalListSortingStrategy}>
              <Tbody>
                {filteredFields.map((field) => (
                  <FieldItemRow
                    key={field.id}
                    field={field}
                    isLoading={isUpdatingField || isUpdatingAll}
                    isReorderDisabled={filteredFields.length !== fields.length}
                    onUpdateField={onUpdateField}
                    onFieldDeleted={onFieldDeleted}
                    onRequestEditDefinition={
                      field.is_custom
                        ? () => {
                            setFieldToEdit(field)
                            editFieldModal.onOpen()
                          }
                        : undefined
                    }
                  />
                ))}
              </Tbody>
            </SortableContext>
          </Table>
        </TableContainer>
      </DndContext>

      <FieldDefinitionModal
        field={fieldToEdit}
        recordType={props.recordType}
        apps={props.apps}
        onChange={(field) => {
          const updated = { ...field, key_field: true }

          setFields((prev) => {
            if (prev.find((item) => item.id === field.id)) {
              // update existing
              return prev.map((item) => (item.id === field.id ? updated : item))
            } else {
              // add new, default to key_field
              return prev.concat(updated)
            }
          })
          setFieldToEdit(null)
        }}
        {...editFieldModal}
      />
    </Box>
  )
}

interface FieldItemRowProps {
  field: PersistedFieldDefinition
  isLoading?: boolean
  isReorderDisabled?: boolean
  onUpdateField: (field: PersistedFieldDefinition) => void
  onFieldDeleted: (id: string) => void
  onRequestEditDefinition?: () => void
}

function FieldItemRow({
  field,
  isLoading,
  isReorderDisabled,
  onUpdateField,
  onFieldDeleted,
  onRequestEditDefinition
}: FieldItemRowProps) {
  const { attributes, listeners, transform, transition, setNodeRef, setActivatorNodeRef, isDragging } = useSortable({
    id: field.id,
    disabled: isReorderDisabled
  })

  const isSalesforce = isSalesforceField(field)
  const isHubSpot = isHubSpotField(field)

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    outline: 'none',
    zIndex: isDragging ? 10 : 1
  }

  const toggleKeyField = useCallback(() => {
    onUpdateField({ ...field, key_field: !field.key_field })
  }, [field, onUpdateField])

  return (
    <Tr
      ref={setNodeRef}
      style={style}
      {...attributes}
      bg={isDragging ? 'white' : 'transparent'}
      py={1.5}
      px={1.5}
      rounded="md"
      fontSize="sm"
      shadow={isDragging ? 'lg' : 'none'}
      position="relative"
      cursor="unset"
      role="group"
    >
      <Td>
        <Flex gap={2} alignItems="center">
          {isReorderDisabled ? (
            <Box minW={5} height={5} />
          ) : (
            <IconButton
              aria-label="Reorder"
              size="xs"
              height={5}
              minW={4}
              variant="unstyled"
              color={isDragging ? 'gray.800' : 'gray.400'}
              _hover={{ color: 'gray.700' }}
              cursor={isDragging ? 'grabbing' : 'grab'}
              icon={<IconGripHorizontal size={14} />}
              style={{ touchAction: 'none' }}
              ref={setActivatorNodeRef}
              {...listeners}
            />
          )}
          <Flex flex="1 1 auto" gap={1.5} alignItems="center">
            {dataTypeIcons[field.data_type] && (
              <Icon as={dataTypeIcons[field.data_type]} boxSize={4} color="gray.500" />
            )}
            <Text fontWeight="medium" isTruncated>
              {field.label}
            </Text>
          </Flex>
        </Flex>
      </Td>
      <Td>
        <Text fontWeight="medium" textTransform="capitalize">
          {field.data_type}
        </Text>
      </Td>
      <Td>
        {isSalesforce ? (
          <Badge
            fontSize="xs"
            fontWeight="medium"
            variant="regular"
            colorScheme="blue"
            alignItems="center"
            gap={0.5}
            display="inline-flex"
          >
            <SalesforceIcon color="salesforce" boxSize={3} />
            Salesforce
          </Badge>
        ) : isHubSpot ? (
          <Badge
            fontSize="xs"
            fontWeight="medium"
            variant="regular"
            colorScheme="orange"
            alignItems="center"
            gap={0.5}
            display="inline-flex"
          >
            <HubSpotIcon color="hubspot" boxSize={3} />
            HubSpot
          </Badge>
        ) : (
          <Badge fontSize="xs" fontWeight="medium" variant="regular" colorScheme={field.is_custom ? 'purple' : 'gray'}>
            {field.is_custom ? 'Custom' : 'Default'}
          </Badge>
        )}
      </Td>
      <Td w="1px">
        <Flex gap={2} alignItems="center">
          {field.key_field ? (
            <Tooltip label="Remove from higlights">
              <IconButton
                aria-label="Remove from highlights"
                size="xs"
                variant="ghost"
                icon={<IconEye size={16} />}
                onClick={toggleKeyField}
              />
            </Tooltip>
          ) : (
            <Tooltip label="Add to highlights">
              <IconButton
                aria-label="Add to highlights"
                size="xs"
                variant="ghost"
                color="gray.400"
                icon={<IconEyeOff size={16} />}
                onClick={toggleKeyField}
              />
            </Tooltip>
          )}
          <FieldDefinitionMenu
            field={field}
            isLoading={isLoading}
            onUpdateField={onUpdateField}
            onRequestEditDefinition={onRequestEditDefinition}
            onFieldDeleted={onFieldDeleted}
          />
        </Flex>
      </Td>
    </Tr>
  )
}

function isSalesforceField(field: PersistedFieldDefinition) {
  return ['salesforce_data.', 'salesforce_contact_data.', 'salesforce_lead_data.', 'salesforce_opportunity.'].some(
    (key) => field.data_source?.startsWith(key)
  )
}

function isHubSpotField(field: PersistedFieldDefinition) {
  return ['hubspot_data.', 'hubspot_contact_data.'].some((key) => field.data_source?.startsWith(key))
}

function isTraitField(field: PersistedFieldDefinition) {
  return field.data_source?.startsWith('account_traits') || field.data_source?.startsWith('profile_traits')
}
