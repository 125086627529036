import { CircularProgress, HStack, Stack, Text } from '@chakra-ui/react'
import { IconCheck } from '@tabler/icons-react'
import isEqual from 'lodash/isEqual'
import React from 'react'
import { useActionCableChannel } from '../../../../channels/use-channel'
import { ListSync } from '../../../../types/StaticList'
import CircleIcon from '../../../ui/CircleIcon'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { TimeAgo } from '../../../ui/TimeAgo'
import { humanize } from '../../accounts/facets/filter-cloud'

export interface StaictListChannelData {
  action: 'started' | 'results'
  sync: ListSync
}

export function Sync(props: { sync: ListSync; onUpdated: (sync: ListSync) => void }) {
  const sync = props.sync

  useActionCableChannel('StaticListSyncChannel', { sync_id: sync.id }, (message: StaictListChannelData) => {
    if (!isEqual(message.sync, sync)) {
      props.onUpdated(message.sync)
    }
  })

  return (
    <Stack fontSize="xs" spacing="1">
      <HStack justifyContent={'space-between'}>
        <Stack spacing="0">
          <Text fontWeight={'semibold'}>{sync.subject_type}s:</Text>
          <HStack>
            <Text
              color={sync.status === 'incomplete' ? 'orange.500' : sync.status === 'pending' ? 'gray.500' : 'green.500'}
            >
              {humanize(sync.status)}
            </Text>
            {sync.status === 'incomplete' && (
              <HelpTooltip>
                Your sync is incomplete. It could have failed due to network errors, or canceling an import early.
              </HelpTooltip>
            )}
            {sync.status === 'pending' && (
              <HelpTooltip>Your sync is about to start. You should start seeing results soon.</HelpTooltip>
            )}
            {sync.status === 'processing' && (
              <HelpTooltip>We're processing your data. You should see more results soon.</HelpTooltip>
            )}
          </HStack>
        </Stack>

        <HStack>
          <Text css={{ fontVariantNumeric: 'tabular-nums' }}>
            {sync.processed_count.toLocaleString()}/{sync.item_count.toLocaleString()}
          </Text>
          {sync.processed_count >= sync.item_count && (
            <CircleIcon icon={IconCheck} variant="solid" colorScheme="green" />
          )}
          {sync.processed_count < sync.item_count && (
            <CircularProgress
              size="20px"
              color={sync.status === 'incomplete' ? 'orange.500' : 'purple.500'}
              isIndeterminate={sync.status === 'pending'}
              value={sync.processed_count}
              max={sync.item_count}
            />
          )}
        </HStack>
      </HStack>

      <Stack fontSize="xs" spacing="0" color="gray.500">
        <Text>
          Sync started <TimeAgo time={sync.created_at} />
        </Text>
        <Text>
          Last updated <TimeAgo time={sync.updated_at} />
        </Text>
      </Stack>
    </Stack>
  )
}
