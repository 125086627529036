import PageTitle from '@app/components/ui/PageTitle'
import type { Account } from '@app/types/Account'
import type { Apps, CatalogApp } from '@app/types/App'
import type { ProjectActions } from '@app/types/AppActions'
import { Stack } from '@chakra-ui/react'
import React from 'react'
import { KQLByDay } from '../../../types/KQL'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { TopBarContent } from '../../ui/TopBarContext'
import PageLayout from '../../ui/PageLayout'
import { projectPath } from '../../ui/ProjectsContext'
import { isLimitedAccount, useEntitlements } from '../../ui/useEntitlements'
import { KqlDefinition } from '../kql_definitions/types'
import DetailsPage from './components/DetailsV2/DetailsPage'

export interface AccountShowProps {
  account: Account
  project_actions: ProjectActions
  apps: Apps
  catalog: CatalogApp[]
  kqls_by_day: KQLByDay[]
  kql_definitions: KqlDefinition[]
}

export default function Show(props: AccountShowProps) {
  const entitlements = useEntitlements()
  const isRedacted = isLimitedAccount(entitlements, props.account)
  const name = props.account.company.name ?? props.account.company.domain

  return (
    <PageLayout size="full" maxW="min(100%, 1450px)">
      <Stack spacing="6" w="100%" margin="0 auto">
        {!isRedacted && <PageTitle skipRendering>{name}</PageTitle>}

        <TopBarContent>
          <Breadcrumb
            paths={[
              { path: projectPath('/accounts'), title: 'Accounts' },
              { path: window.location.toString(), title: isRedacted ? 'Unlock to see' : name! }
            ]}
          />
        </TopBarContent>

        <DetailsPage
          account={props.account}
          signalsByDay={props.kqls_by_day}
          signalDefns={props.kql_definitions}
          apps={props.apps}
          catalog={props.catalog}
          actions={props.project_actions}
        />
      </Stack>
    </PageLayout>
  )
}
