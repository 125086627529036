import {
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  LinkBox,
  LinkOverlay,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react'
import {
  Icon as TablerIcon,
  IconApps,
  IconChevronDown,
  IconChevronRight,
  IconPlus,
  IconUpload,
  IconUsers,
  IconWorldWww
} from '@tabler/icons-react'
import pluralize from 'pluralize'
import React from 'react'
import { greeting } from '../../../lib/dayjs'
import router from '../../../lib/router'
import { AccountView } from '../../../types/AccountView'
import { Space } from '../../../types/Space'
import { StaticList } from '../../../types/StaticList'
import Avatar from '../../ui/Avatar'
import { Card, LightBgCard } from '../../ui/Card'
import CircleIcon from '../../ui/CircleIcon'
import EmptyState from '../../ui/EmptyState'
import { openWishForm } from '../../ui/FeedbackForm'
import { Iconify } from '../../ui/Iconify'
import { BuildingIcon, LinkedinBoxIcon, SalesforceIcon } from '../../ui/icons'
import { HubSpotIcon } from '../../ui/icons/HubspotIcons'
import { SearchIcon } from '../../ui/icons/SearchIcon'
import { MiddotDivider } from '../../ui/Middot'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import SquareIcon from '../../ui/SquareIcon'
import { TimeAgo } from '../../ui/TimeAgo'
import { TopBarContent } from '../../ui/TopBarContext'
import useLocation from '../../ui/useLocation'
import { useCurrentUser } from '../../ui/UserContext'
import { NewListModal } from '../account_views/components/NewListModal'
import { accountViewPath } from '../account_views/lib/list-paths'
import { mergeParams } from '../icps/types'
import { useTrackRecentNavItems } from '../navigation/useTrackRecentNavItems'
import { SpaceMembers } from '../spaces/components/Members'

type CombinedList = (AccountView | StaticList) & {
  class_name?: string
  permissions?: any
}

function isAccountView(item: Space | CombinedList): item is AccountView {
  return item.class_name === 'AccountView'
}

function isStaticList(item: Space | CombinedList): item is StaticList {
  return item.class_name === 'StaticList'
}

function isSpace(item: Space | CombinedList): item is Space {
  return item.class_name === 'Space'
}

interface Props {
  mission_control: Space | null
  lists: CombinedList[]
  has_data?: boolean
  has_lists_or_spaces?: boolean
  spaces: Space[]
}

export default function Home(props: Props) {
  const user = useCurrentUser()
  const newListModal = useDisclosure()
  const buttonSize = useBreakpointValue({ base: 'sm', md: 'md' })

  return (
    <PageLayout size="full" gap={8}>
      <PageTitle skipRendering>Home</PageTitle>

      <NewListModal {...newListModal} />
      <TopBarContent>
        <Flex
          w="100%"
          alignSelf="center"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          gap={3}
          py={3}
          px={[1, 1, 3, 4]}
        >
          <Heading flex="none" size="md" fontWeight="medium">
            {greeting()}, {user.firstName} 👋
          </Heading>

          {props.has_lists_or_spaces && (
            <HStack flex="none" spacing={3}>
              <Button
                as="a"
                href={projectPath(`/views/new`)}
                onClick={(e) => {
                  // prevent the link from navigating
                  // unless opening in a new tab
                  if (e.ctrlKey || e.metaKey || e.shiftKey) {
                    return true
                  }

                  newListModal.onOpen()
                  e.preventDefault()
                  return false
                }}
                size={buttonSize}
                rounded="md"
                variant="outline"
                leftIcon={<Icon as={IconPlus} boxSize={4} color="purple.600" />}
                iconSpacing={1}
              >
                Create List
              </Button>
              <Button
                as="a"
                href={projectPath(`/prospector`)}
                size={buttonSize}
                rounded="md"
                variant="outline"
                leftIcon={<Icon as={SearchIcon} boxSize={4} color="purple.600" />}
                iconSpacing={1.5}
              >
                Start Search
              </Button>
            </HStack>
          )}
        </Flex>
      </TopBarContent>

      {!props.has_data && (
        <Card p={5}>
          <HStack spacing={3} alignItems="flex-start">
            <Stack spacing={0}>
              <Heading size="sm" fontWeight="semibold">
                Create a list
              </Heading>
              <Text fontSize="sm" color="gray.600">
                Get started by building a list of companies or people that you can enrich, prioritize and work.
              </Text>
            </Stack>
          </HStack>

          <Grid
            gridAutoRows="1fr"
            templateColumns={['repeat(1, 1fr)', 'repeat(auto-fit, minmax(240px, 1fr))']}
            gap={3}
            marginTop={5}
          >
            <DataSourceCard
              name="Import people from a CSV"
              description="Add email addresses from a CSV"
              logo={IconUpload}
              href={projectPath(`/profiles/imports/new`)}
            />
            <DataSourceCard
              name="Import companies from a CSV"
              description="Add company domains from a CSV"
              logo={IconUpload}
              href={projectPath(`/accounts/imports/new`)}
            />
            <DataSourceCard
              name="Search for people or companies"
              description="Find people matching your ideal buyer persona"
              logo={SearchIcon}
              href={projectPath(`/prospector`)}
            />
            <DataSourceCard
              name="Chrome Extension for LinkedIn"
              description="Track people reacting and commenting on LinkedIn posts."
              logo={<LinkedinBoxIcon color="linkedin.700" />}
              requiresAccess
            />
            <DataSourceCard
              name="Website tracking"
              description="Discover signals from people on your site, docs, blog, or app."
              logo={<IconWorldWww />}
              href={projectPath(`/settings/install`)}
            />
            <DataSourceCard
              name="Salesforce"
              description="Sync your Salesforce contacts, leads, and accounts into Koala."
              logo={<SalesforceIcon color="salesforce" />}
              href={projectPath(`/apps/salesforce`)}
            />
            <DataSourceCard
              name="HubSpot"
              description="Sync your HubSpot contacts and companies into Koala."
              logo={<HubSpotIcon color="hubspot" />}
              href={projectPath(`/apps/hubspot`)}
            />
            <DataSourceCard
              name="LinkedIn"
              description="Track people reacting and commenting on your company's LinkedIn."
              logo={<LinkedinBoxIcon color="linkedin.700" />}
              href={projectPath(`/apps/linkedin`)}
              requiresAccess
            />
            <DataSourceCard
              name="GitHub"
              description="Track stargazers and interactions across your top repos."
              logo="https://logo.clearbit.com/github.com"
              href={projectPath(`/apps/github`)}
              requiresAccess
            />
            <DataSourceCard
              name="Slack Community"
              description="Track members joining and interacting across your channels."
              logo="https://cdn.worldvectorlogo.com/logos/slack-new-logo.svg"
              href={projectPath(`/apps/slack_community`)}
              requiresAccess
            />
            <DataSourceCard
              name="Discord"
              description="Track members joining and interacting across your servers."
              logo="https://logo.clearbit.com/discord.com"
              href={projectPath(`/apps/discord`)}
              requiresAccess
            />
            <DataSourceCard
              name="Other sources"
              description="Explore more integrations and data sources."
              logo={IconApps}
              href={projectPath(`/apps`)}
            />
          </Grid>
        </Card>
      )}

      {(props.mission_control?.account_views ?? []).length > 0 && (
        <>
          <Stack spacing={3}>
            <Heading size="sm" fontWeight="semibold">
              My views
            </Heading>

            <Grid templateColumns={['repeat(1, 1fr)', 'repeat(auto-fill, minmax(280px, 1fr))']} gap={5}>
              {props.mission_control?.account_views?.map((list) => <ListCard key={list.id} list={list} />)}
            </Grid>
          </Stack>

          <Divider />
        </>
      )}

      {props.has_lists_or_spaces ? (
        <ListTable lists={props.lists} spaces={props.spaces} />
      ) : props.has_data ? (
        <EmptyState
          size="md"
          heading="Get Started"
          description="Create a new list or start a search for companies/people"
          icon={IconPlus}
        >
          <HStack flex="none" spacing={3}>
            <Button
              as="a"
              href={projectPath(`/views/new`)}
              onClick={(e) => {
                // prevent the link from navigating
                // unless opening in a new tab
                if (e.ctrlKey || e.metaKey || e.shiftKey) {
                  return true
                }

                newListModal.onOpen()
                e.preventDefault()
                return false
              }}
              size={buttonSize}
              rounded="md"
              variant="outline"
              leftIcon={<Icon as={IconPlus} boxSize={4} color="purple.600" />}
              iconSpacing={1}
            >
              Create List
            </Button>
            <Button
              as="a"
              href={projectPath(`/prospector`)}
              size={buttonSize}
              rounded="md"
              variant="outline"
              leftIcon={<Icon as={SearchIcon} boxSize={4} color="purple.600" />}
              iconSpacing={1.5}
            >
              Start Search
            </Button>
          </HStack>
        </EmptyState>
      ) : null}
    </PageLayout>
  )
}

const nouns = {
  account: 'accounts',
  profile: 'people'
}

function ListCard(props: { list: CombinedList }) {
  const list = props.list
  const href = list.permalink || (isStaticList(list) ? projectPath(`/lists/${list.id}`) : accountViewPath(list))
  const trackPageKey = isStaticList(list) ? `staticList:${list.id}` : `accountView:${list.id}`
  const { trackRecentNavItem } = useTrackRecentNavItems()
  const currentUser = useCurrentUser()

  return (
    <LinkBox>
      <Card h="100%" px={5} py={4}>
        <Flex flexDir="column" gap={4} alignItems="flex-start">
          <CircleIcon
            icon={list.kind === 'account' ? BuildingIcon : IconUsers}
            iconSize={18}
            size="32px"
            colorScheme={list.kind === 'account' ? 'purple' : 'blue'}
          />

          <Stack width="100%" spacing={1}>
            <Heading size="xs" fontSize="15px">
              <LinkOverlay
                href={href}
                onClick={() => {
                  trackRecentNavItem(trackPageKey)
                }}
              >
                {list.name}
              </LinkOverlay>
            </Heading>

            <Flex gap={4} alignItems="center" justifyContent="space-between">
              <HStack color="gray.500" fontSize="sm" spacing={1.5} divider={<MiddotDivider />} flexWrap="wrap">
                <Text flex="none" css={{ fontVariantNumeric: 'tabular-nums' }}>
                  {(list.counts?.all ?? 0).toLocaleString()} {pluralize(nouns[list.kind], list.counts?.all || 0)}
                </Text>

                {list.created_by_user && (
                  <Text isTruncated maxW="200px">
                    Created by {getAuthorName(list.created_by_user, currentUser)}
                  </Text>
                )}
              </HStack>
            </Flex>
          </Stack>
        </Flex>
      </Card>
    </LinkBox>
  )
}

interface DataSourceCardProps {
  name: string
  description: string
  href?: string
  logo: JSX.Element | TablerIcon | string | React.FC
  planTier?: string
  onSelect?: () => void
  requiresAccess?: boolean
}

function DataSourceCard(props: DataSourceCardProps) {
  const shouldLink = props.href && !props.requiresAccess
  const as = shouldLink ? LinkBox : Box
  const overlay = shouldLink ? <LinkOverlay href={props.href}>{props.name}</LinkOverlay> : props.name

  return (
    <LightBgCard
      as={as}
      p={5}
      rounded="lg"
      transition="transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
      cursor="pointer"
      onClick={
        props.requiresAccess
          ? () =>
              openWishForm({
                title: `Request access to ${props.name}`,
                placeholder: 'Let us know how you would like to use the source',
                variant: 'modal',
                preamble: `Requesting access to ${props.name} integration`
              })
          : undefined
      }
      _hover={{ borderColor: 'gray.300', transform: 'scale(102%)' }}
    >
      <Stack spacing={4} height="100%">
        <HStack spacing="2" justifyContent="space-between" alignItems="flex-start">
          <Iconify icon={props.logo} size={28} />

          <Flex alignItems="center" gap={2}>
            {props.requiresAccess && (
              <Box color="pink.600" bg="pink.50" paddingX={2} paddingY={0.5} rounded="full">
                <Text fontSize="xs" fontWeight="semibold">
                  Early Access
                </Text>
              </Box>
            )}
            {props.planTier && (
              <Box color="orange.600" bg="orange.50" paddingX={2} paddingY={0.5} rounded="full">
                <Text fontSize="xs" fontWeight="semibold">
                  {props.planTier.toUpperCase()}
                </Text>
              </Box>
            )}
          </Flex>
        </HStack>

        <Stack flex="1" spacing={2}>
          <Heading size="xs">{overlay}</Heading>
          <Text fontSize="sm" color="gray.500">
            {props.description}
          </Text>
        </Stack>
      </Stack>
    </LightBgCard>
  )
}

function getAuthorName(user: { id: string; name: string; email: string }, currentUser: { id?: string }) {
  return user.id === currentUser.id ? 'you' : user.name || user.email
}

const tabs = [
  { value: undefined, label: 'Recent' },
  { value: 'mine', label: 'Mine' },
  { value: 'shared', label: 'Shared' }
]

interface ListTableProps {
  // TODO combine on the backend
  lists: CombinedList[]
  spaces: Space[]
}

function ListTable({ lists, spaces }: ListTableProps) {
  const location = useLocation()
  const combined = [...spaces, ...lists]
  const [expanded, setExpanded] = React.useState<Record<string, boolean>>({})
  const params = new URLSearchParams(location.search)
  const listsTab = params.get('lists') || 'recent'

  return (
    <Stack spacing={3}>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading size="sm" fontWeight="semibold">
          Lists
        </Heading>

        <Link fontSize="sm" href={projectPath('/views')} color="purple.600" _hover={{ textDecoration: 'underline' }}>
          See all
        </Link>
      </Flex>

      <Tabs
        size="xs"
        variant="line"
        isManual
        defaultIndex={listsTab === 'recent' ? 0 : listsTab === 'mine' ? 1 : listsTab === 'shared' ? 2 : 0}
        onChange={(index) => {
          const tab = tabs[index]?.value
          const path = mergeParams(location.href, { lists: tab })
          router.visit(path)
        }}
      >
        <TabList>
          {tabs.map((tab) => (
            <Tab key={tab.value || tab.label}>{tab.label}</Tab>
          ))}
        </TabList>
      </Tabs>

      <Stack spacing={1} paddingBottom="100px" maxW="100%" overflow="auto">
        <Flex
          color="gray.500"
          alignItems="center"
          py={1}
          px={2}
          gap={1.5}
          borderBottom="1px solid"
          borderColor="border.lightest"
        >
          <Text flex="1 1 auto" minW="300px" fontSize="xs" fontWeight="medium">
            Name
          </Text>

          <Text flex="none" fontSize="xs" fontWeight="medium" width="150px">
            Entries
          </Text>

          <Text flex="none" fontSize="xs" fontWeight="medium" width="200px">
            {listsTab === 'recent' ? 'Last opened by me' : 'Last modified'}
          </Text>
        </Flex>

        <Stack spacing="1px">
          {combined.map((item) => (
            <ListItemRow
              key={item.id}
              item={item}
              isExpanded={expanded[item.id]}
              setExpanded={setExpanded}
              showLastViewed={listsTab === 'recent'}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}

interface ListItemRowProps extends BoxProps {
  item: CombinedList | Space
  showLastViewed?: boolean
  isExpanded?: boolean
  setExpanded?: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
}

function ListItemRow({ item, isExpanded, setExpanded, showLastViewed, ...rest }: ListItemRowProps) {
  const { trackRecentNavItem } = useTrackRecentNavItems()

  let href: string | undefined
  let trackPageKey: string | undefined
  const Wrapper = isSpace(item) ? Box : LinkBox

  if (isStaticList(item)) {
    href = item.permalink || projectPath(`/lists/${item.id}`)
    trackPageKey = `staticList:${item.id}`
  } else if (isAccountView(item)) {
    href = item.permalink || accountViewPath(item)
    trackPageKey = `accountView:${item.id}`
  }

  return (
    <Wrapper>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        py={2}
        px={2}
        gap={1.5}
        rounded="lg"
        _hover={{ bg: 'gray.50' }}
        cursor="pointer"
        onClick={
          isSpace(item) && setExpanded
            ? () => setExpanded((prev) => ({ ...prev, [item.id]: !prev[item.id] }))
            : undefined
        }
        {...rest}
      >
        <Flex alignItems="center" justifyContent="center" flex="none" width="12px" height="20px">
          {isSpace(item) && (
            <IconButton
              aria-label="Expand/collapse"
              icon={<Icon as={isExpanded ? IconChevronDown : IconChevronRight} boxSize={4} />}
              size="tiny"
              color="gray.400"
              variant="ghost"
            />
          )}
        </Flex>

        <ModelIcon model={item} />

        <Flex flex="1 1 auto" minW="300px" alignItems="center" gap={2} justifyContent="space-between" paddingRight={4}>
          <Text flex="1 1 auto" fontSize="sm" fontWeight="medium" userSelect="none" isTruncated>
            {isSpace(item) ? (
              item.name
            ) : (
              <LinkOverlay
                href={href}
                onClick={() => {
                  if (trackPageKey) {
                    trackRecentNavItem(trackPageKey)
                  }
                }}
              >
                {item.name}
              </LinkOverlay>
            )}
          </Text>
          {isSpace(item) && <SpaceMembers members={item.members} />}
        </Flex>

        <Text flex="none" fontSize="sm" color="gray.500" width="150px" css={{ fontVariantNumeric: 'tabular-nums' }}>
          {!isSpace(item) && (
            <>
              {item.counts?.all ? (
                <Text as="span">
                  {item.counts.all.toLocaleString()} {pluralize(nouns[item.kind], item.counts.all)}
                </Text>
              ) : (
                <Text as="span" color="gray.300">
                  &mdash;
                </Text>
              )}
            </>
          )}
        </Text>

        <Text flex="none" fontSize="sm" color="gray.500" width="200px" css={{ fontVariantNumeric: 'tabular-nums' }}>
          {showLastViewed && !isSpace(item) ? (
            <TimeAgo time={item.last_viewed_at!} canToggle={false} />
          ) : (
            <TimeAgo time={item.updated_at!} canToggle={false} />
          )}
        </Text>
      </Flex>

      {isExpanded && isSpace(item) && (
        <Stack spacing="1px" marginTop="1px">
          {item.account_views?.map((list) => <ListItemRow key={list.id} item={list} paddingLeft={10} />)}
        </Stack>
      )}
    </Wrapper>
  )
}

interface ModelIconProps {
  model: Space | CombinedList
}

function ModelIcon(props: ModelIconProps) {
  if (isStaticList(props.model) || isAccountView(props.model)) {
    return (
      <SquareIcon
        icon={props.model.kind === 'account' ? BuildingIcon : IconUsers}
        iconSize="16px"
        size="24px"
        // colorScheme={props.model.kind === 'account' ? 'purple' : 'blue'}
        colorScheme="gray"
      />
    )
  }

  return <Avatar size="xs" fontSize="13px" fontWeight="semibold" rounded="md" name={props.model.name.split(' ')[0]} />
}
